import React, {useState} from 'react'
import s from '../styles/University.module.scss'
import {Lightbox} from 'Components/Lightbox'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import { uploadImageCDN, validateFiles, makeId } from 'Utils/media'
import ImgUploader from 'Components/Input/ImgUploader';
import {BsCloudUpload} from 'react-icons/bs'
import { TextAreaInput, TextInput } from 'Input/Input'
import {Root} from 'Root' 
import {isMentor} from 'Utils/sessions'
import { CustomPagination } from 'Components/Pagination';

export const HighlightReel = ({highlights, ipedsid, session}) => {
    const [photoIndex, setPhotoIndex] = useState(null)
    const toggle = (idx) => {setPhotoIndex(idx);}
    const [show,setShow] = useState(false)
    const [title, setTitle] = useState(null)
    const [caption, setCaption] = useState(null)
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState(null)
    const [uploadErrors, setUploadErrors] = useState([])
    const [content, setContent] = useState(null)
    const [selected, setSelected] = useState(1)
    const itemsPerPage = 10;

    const handleHide = () => setShow(false)
    const handleShow = () => setShow(true)
    const [loading, setLoading] = useState(false)

    function onError(){
        setError('Error saving changes, please try again')   
        setLoading(false) 
      }

      function onDrop (event) {
        setSuccess(false)
        if (event.target.files.length > 0) {
            setError(null)
          setUploadErrors([])
          const errors = validateFiles(event.target.files, 5242880, ['.jpg', '.png', '.jpeg', '.mp4'])
          if (Object.keys(errors).length > 0) {
            setUploadErrors(errors)
            return
          } else {
              setContent(event.target.files[0])
          }
        }
      }

      function handleSubmit() {
        setError(null)
        setLoading(true)
        const photoId = makeId(10)
        function submitHighlight() {
        let data = {
            url: `highlight-photo-${session.id}-${photoId}`,
            type: content.type.includes('video') ? 'VIDEO' : 'IMAGE',
        }

        if (caption){
            data.caption = caption
        } else {
            setError('A title is required')
            return
        }

        if (title) {
            data.title = title
        }
            //data = {url: String, caption: String, type: VIDEO | PHOTO}
          Root.userApi.submitUserContent(ipedsid, session.id, data)
          .then(()=> { 
              setLoading(false);
              setContent(null);
              setSuccess(true);
              setCaption(null);
              setTitle(null)
            }).catch(()=> onError())
        }
  
        uploadImageCDN(content, `highlight-photo-${session.id}-${photoId}`, onError, submitHighlight)
      }

    return <div> 
        {isMentor(session) && session.college === ipedsid && <Button className='btnOutline mb-2' onClick={handleShow}>+ Upload Media</Button>}
        <div className={`${s.highlights} responsiveGridMd`}>
            {highlights.slice((selected*itemsPerPage)-itemsPerPage, ((selected-1)*itemsPerPage) + itemsPerPage).map((highlight, idx) => {
                return (<div key={idx} onClick={()=>toggle(idx)}>
                            {highlight.type === 'VIDEO' ? <>
                            <video alt={"University"}  src={highlight.url} onClick={()=>toggle(idx)} playsInline autoPlay loop muted/>
                            <p className='fs-13 text-muted mb-0 pb-0 trunc'>{highlight.caption}</p>
                            </>
                            : <img alt={"University"} src={highlight.url} onClick={()=>toggle(idx)}></img>}
                        </div>
                )}
            )}
            <Lightbox 
                contentList={highlights} 
                idx={photoIndex}
                onHide={()=>setPhotoIndex(null)}
                setIdx={setPhotoIndex}
            /> 
        </div>
        {highlights.length > itemsPerPage && <CustomPagination setSelected={setSelected} selected={selected} numPages={Math.ceil(highlights.length/itemsPerPage)}/>}

        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Media</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                <div className='d-flex justify-content-center align-items-center p-3' 
                style={{backgroundColor:'#D4DDE5', borderRadius:'10px', height: '250px'}}>
                    <span>
                {content ? <>
                    {content.type.includes('video') ?
                        <video  className='d-block mx-auto mt-3' style={{maxHeight:'100px', maxWidth:'100%'}} src={URL.createObjectURL(content)}/> 
                       : <img  className='d-block mx-auto mt-3' style={{maxHeight:'100px', maxWidth:'100%'}} src={URL.createObjectURL(content)}/> 
                  
                     }</>  
                    :  <p className='fs-13 text-center'>
                        <BsCloudUpload size={50}/>
                        <br/>
                        Max file size: 2GB, Max video length: 5min, Accepted: jpg, png, mp4
                    </p>
                    }
                    <ImgUploader accept={["image/*", "video/*"]} buttonClassName='wide btnLink' buttonText={loading ? 'Uploading...' : 'Browse Media'} onDrop={onDrop}/>
                    <p style={{textAlign:'center', color:'grey', marginBottom:0, fontSize:'0.8rem', marginTop:'1rem'}}>
            * Please note uploading larger files may take several minutes. Please stay on page as the content is uploading *</p>
                    </span>
                </div>

                    <TextInput label='Title' errors={{}} containerClassName='mt-3' name={'title'}
                               value={title || ''} onChange={(e)=>{setTitle(e.target.value)}} type={'text'}
                               placeholder={"A simple title"}/>
                <TextAreaInput label='Description' containerClassName='mt-3' onChange={(e)=>{setCaption(e.target.value)}} value={caption} placeholder={"Write description..."} errors={{}}/>
                <Button onClick={ content && handleSubmit} className='btnFill md mt-3'>Upload Media</Button>
                {success && <p className='mt-1 fs-13 green'>Upload complete, go to your dashboard to manage your uploads</p>}
                <p className='fs-13 text-muted my-4 text-left'>To ensure the safety of content on our platform, all images will go through an 
                    approval process before being displayed on the site.</p>
                {error && <p className='form-error'>{error}</p>}
                    {uploadErrors.map((error, idx) => {
                    return <p key={idx} className='form-error text-center m-0' >{error.type}</p>
                })}
                </div>
            </Modal.Body>
        </Modal>
    </div>
}