
import axios from 'axios';
import * as vars from './vars';

export function getCurrentAmbassadorProgram () {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/ambassadors/program/current`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
    })
}

export function authenticateAmbassador (identifier, passkey) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/ambassadors/ambassador/authenticate',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
            identifier: identifier,
            passkey: passkey
        }
    })
}

export function updateAmbassador (ambassadorId, firstName, lastName, tos) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/ambassadors/ambassador/information',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
            ambassadorId: ambassadorId,
            firstName: firstName,
            lastName: lastName,
            tos: tos
        }
    })
}

export function getAmbassadorData (userId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/ambassadors/ambassador?userId=${userId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
    })
}

export function sendAmbassadorReferral (email, ambassadorId, program) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/ambassadors/referrals',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { email: email, ambassadorId: ambassadorId, program: program }
    })
}

export function getReferralProgram (userId, program) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/misc/referrals/programs/' + program,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { userId: userId }
    })
}

export function getLeaderboardEntries (program) {
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/misc/' + program + '/referrals/leaderboard',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : { }
  })
}

export function getLeaderboardRanking (userId, program) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/misc/' + program + '/referrals/leaderboard/rankings?userId=' + userId,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { }
    })
}

export function getReferralStatistics (userId, program) {
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/users/' + userId + '/referrals/statistics?program=' + program,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : { }
  })
}

export function sendStudentReferral (email, userId, program) {
      return axios({
          method: 'POST',
          url: vars.baseURL + '/api/v2/metrics/' + program + '/referrals',
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data : { email: email, referrerId: userId }
      })
  }

export function downloadHermes (referrer, ipAddress) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/metrics/downloads/hermes',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { referrer: referrer, ipAddress: ipAddress }
    })
}

export function logPageLanding (page, referrer, referrerGroup = "") {
    return axios({
        method: 'PUT',
        url: vars.baseURL + '/api/v2/metrics/landings/' + page,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { referrer: referrer, referrerGroup: referrerGroup }
    })
}
