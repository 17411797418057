import React, { useState, useEffect } from 'react'
import {Root} from 'Root'
import Button from 'react-bootstrap/Button'

const PaymentDropIn = ({session, meetingId, promo, setHasPayed}) => {
  var dropin = require('braintree-web-drop-in')
  const [clientToken, setClientToken] = useState(null)
  const [instance, setInstance] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
      // Get a client token for authorization from your server
      Root.collegeApi.getPaymentClientToken(session.id).then(rsp => {
        if (rsp.status === 200 && rsp.data) {
          setClientToken(rsp.data)
        } else {
          console.log(rsp)
          setErrorMessage('Something went wrong, please contact support for assistance')
        }
      }).catch(() =>
        setErrorMessage('Something went wrong, please contact support for assistance')
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clientToken) {
      dropin.create({
        authorization: clientToken,
        container: '#dropin-container',
        dataCollector: {
          kount: true // Required if Kount fraud data collection is enabled
        }
      }).then(instance => {
        setInstance(instance)
      }).catch(() => {
        setErrorMessage('Unable to process payment at this time. Please contact support.')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken])

  function requestPaymentMethod () {
    instance.requestPaymentMethod().then(data => {
      Root.collegeApi.sendNonce(data.nonce, data.deviceData, meetingId, promo).then(rsp => {
        if (rsp.status === 200 && rsp.data) {
          setHasPayed(true)
        } 
      })
    }).catch(() => {
      setErrorMessage('Unable to process payment at this time. Please contact support if error persists.')
    })
  }
  return (
    <div>
      <div id="dropin-container"></div>
      <div className='d-flex justify-content-center'>
        {instance && <Button onClick={requestPaymentMethod} id="submit-button" className={'btnFill wide'}>Purchase</Button>}
      </div>
      {errorMessage && <p className='form-error'>{errorMessage}</p>}
    </div>
  )
}

export default PaymentDropIn
