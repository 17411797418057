import axios from 'axios'
import axiosRetry from 'axios-retry';
import * as vars from './vars';

export function getPaymentClientToken(userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/client_token?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}
    
export function hasPayed (userId, meetingId) {
  const params = new URLSearchParams({userId: userId, meetingId: meetingId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/meeting/payment_received?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}
    
export function updatePaymentStatus (transactionId, amount, status) {
  if (amount) {
      const params = new URLSearchParams({transactionId: transactionId, amount: amount, status: status})
      return axios({
          method: 'POST',
          url: vars.baseURL + '/api/v2/transaction/payout/set_status?' + params,
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {}
      })
  } else {
      const params = new URLSearchParams({transactionId: transactionId, status: status})
      return axios({
          method: 'POST',
          url: vars.baseURL + '/api/v2/transaction/payout/set_status?' + params,
          headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
          data: {}
      })
  }
}
    
export function sendNonce (nonce, deviceData, meetingId, promo) {
  return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/transaction/method/nonce',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {
        paymentNonce: nonce,
        deviceData: deviceData,
        meetingId: meetingId,
        promotionalCode: promo
      }
  })
}
    
export function bankAccountOnFile (userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/payee/initialized?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}
    
export function cardOnFile (userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/payee/debit_card/initialized?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}
    
export function updatePayeeInfoPlaid ( userId ) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/transaction/payee/plaid/token?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {}
  })
}

export function updatePaymentMethod ( userId, method,  data) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/transaction/method/create?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {
        method: method,
        [method]: data,
      }
  })
}
      
export function getPlaidUserToken (userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/payee/plaid/token?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}

export function getPlaidLinkToken (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/transaction/payee/plaid/link?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getPayoutAccounts (userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/payee/payment_methods?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}
    
export function deletePayoutMethod ( userId, data) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'DELETE',
      url: vars.baseURL + '/api/v2/transaction/method/delete?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : data
  })
}

export function setDefaultPayoutMethod (userId, data) {
    const params = new URLSearchParams({userId: userId})
    return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/transaction/method/default?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : data
  })
}
    
export function getEarnings (userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/payee/earnings?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}

export function ssnOnFile (userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/payee/ssn_provided?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}
      
export function setSSN (userId, line1, line2, zipCode, ssn){
      const params = new URLSearchParams({userId: userId})
    return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v2/transaction/payee/ssn?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {
          line1: line1,
          line2: line2,
          zipCode: zipCode,
          ssn: ssn,
      }
  })
}

export function getPaymentHistory (userId) {
  const params = new URLSearchParams({userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/transaction/payments/user?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}

export function validatePromoCode ( userId, promotionalCode, meetingId ) {
    const params = new URLSearchParams({userId: userId, promotionalCode: promotionalCode, meetingId: meetingId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/transaction/method/promotional?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {}
    })
  }

// data = {
//   amountRequested: Double,
//   hoursWorked: Double (Optional),
//   memo: String (Optional),
// }
export function sendInvoice(contractId, data) {
  const params = new URLSearchParams({contractId: contractId})
  return axios({
    method: 'POST',
    url: vars.baseURL + `/api/v2/employment/contracts/${contractId}/invoices?` + params,
    headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
    data : data
  })
}

export function payInvoice(paymentNonce, deviceData, invoiceId) {
  return axios({
    method: 'POST',
    url: vars.baseURL + `/api/v2/transaction/method/nonce`,
    headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
    data : {
      type: "INVOICE",
      paymentNonce: paymentNonce,
      deviceData: deviceData,
      invoiceId: invoiceId
    }
  })
}

// searchParams {companyId, userId}
export function getInvoices (searchParams) {
  const params = new URLSearchParams(searchParams)
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/employment/invoices?' + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {}
  })
}

export function getInvoiceById (invoiceId) {
  return axios({
      method: 'GET',
      url: vars.baseURL + `/api/v2/employment/invoices/${invoiceId}`,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data : {}
  })
}

// api/v2/employment/contracts/{contractId}/invoices
export function getInvoicesByProject(contractId) {
  return axios({
    method: 'GET',
    url: vars.baseURL + `/api/v2/employment/contracts/${contractId}/invoices`,
    headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
    data : {}
  })
}

// data = {
//   action: String [ SETTLE | DISPERSE | REJECT | VOID ]
// }
export function updateInvoiceStatus(invoiceId, status){
  return axios({
      method: 'PATCH',
      url: vars.baseURL + `/api/v2/employment/invoices/${invoiceId}` ,
      headers: { api_key: this.api_key, 'X-XSRF-TOKEN': this.csrfToken},
      data: {
        action: status
      }
  })
}