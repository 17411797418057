import React, {useContext, useEffect, useState} from 'react'
import s from './styles/University.module.scss'
import SideBar from 'Components/Nav/SideBar';
import {Root} from 'Root'
import {useLocation} from 'react-router-dom'
import Card from 'react-bootstrap/esm/Card';
import {urlToHostname} from './utils'
import {
    AiFillHeart,
    AiFillStar,
    AiOutlineCamera,
    AiOutlineHeart,
    AiOutlineLike,
    AiOutlineVideoCamera
} from 'react-icons/ai'
import {TiDocument} from 'react-icons/ti'
import placeholder from 'Static/placeholder.jpeg'
import {CollegeImageLighbox} from 'Components/Lightbox'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/esm/Button'
import About from './Components/About';
import BookMeeting from './Components/BookMeeting';
import ChatNXSTEPAI from './Components/ChatNXSTEPAI';
import {isMentee, isMentor, SessionContext} from 'Utils/sessions'
import {HighlightReel} from './Components/Highlights';
import Review from './Components/Review';

export const fields = {
    academics: 'Academics',
    diversity: 'Diversity',
    campus: 'Campus Scenery',
    athletics: 'Athletics',
    safety: 'Safety',
    partyScene: 'Party Scene',
    professors: 'Professors',
    location: 'Campus Location',
    dorms: 'Dorms',
    campusFood: 'Campus Food Options',
    studentLife: 'Overall Student Life',
    value: 'Overall value',
}

const University = () => {
    const {session, refreshSession} =  useContext(SessionContext)
    const useQuery = () => { return new URLSearchParams(useLocation().search) }
    const collegeName = useQuery().get('name')
    const id = useQuery().get('id')
    const sessionStartTime = new Date().getTime()

    const [college, setCollege] = useState(null)
    const [images, setImages] = useState([])
    const [averageRating, setAverageRating] = useState(null)
    const [reviews, setReviews] = useState([])
    const [reducedRating, setReducedRating] = useState({})
    const [favorites, setFavorites] = useState([])

    const [key, setKey] = useState('about')

    const [highlightVideos, setHighlightVideos] = useState([])
    const [highlightPhotos, setHighlightPhotos] = useState([])

    useEffect(() => {
        Root.collegeApi.getCollege(id).then(rsp => {
            if (rsp.data.length > 0) {
                setCollege(rsp.data[0])
            } else {
                setCollege([])
            }
        }).catch(() =>setCollege([]))
    },[])
    // for local testing
    // const testImg =["https://lh3.googleusercontent.com/p/AF1QipMuMBC-fGT-i2K7I1yvuSlc7RUVT-nPrM37eD4H=s1600","https://lh3.googleusercontent.com/p/AF1QipN70J-a7TG4UGBD3XOb5AxaFJjqIy2bOgTj730v=s1600","https://lh3.googleusercontent.com/p/AF1QipNULmB0sdUZYKUR1caOf-xnQ0fJH3XF0aKzdJnf=s1600","https://lh3.googleusercontent.com/p/AF1QipN_T5oOc0f_adRxXc9aGR_gJj3EFAOtbG_gKmXg=s1600","https://lh3.googleusercontent.com/p/AF1QipOcR8kqudEArM9Qs1D_fbwa2mlTockLRrn7oMj2=s1600","https://lh3.googleusercontent.com/p/AF1QipPhDav5lhkCl2EntHgq_zZZKeKL5ivV0Y47VVl0=s1600","https://lh3.googleusercontent.com/p/AF1QipMtYvGG32_1N3Tzk_vyt6azPWQA7gDh2AzP4wA=s1600","https://lh3.googleusercontent.com/p/AF1QipOejrvrA60Ne_TmnifPxBTwlSeDfavP2WuPrKI=s1600","https://lh3.googleusercontent.com/p/AF1QipPmIaZsT2V0xv7sQYUk-Crn4e6hcguGDZuI1to=s1600","https://lh3.googleusercontent.com/p/AF1QipPqfUQTmMpjS6dEs1rd_c_3ixvYLBc-cEPjNA0=s1600","https://cdn.nxstep.co/generic_college.jpg"]
    useEffect(() => {
        Root.collegeApi.getCollegeImages(id)
        .then(rsp => {setImages(rsp.data)})
        .catch(() => setImages([]))
    }, [])

    useEffect(() => {
        Root.collegeApi.getCollegeHighlights(id, "VIDEO")
        .then(rsp => {setHighlightVideos(rsp.data)})
        .catch(() => setHighlightVideos([]))

        Root.collegeApi.getCollegeHighlights(id, "IMAGE")
        .then(rsp => {setHighlightPhotos(rsp.data)})
        .catch(() => setHighlightPhotos([]))
    }, [])

    useEffect(() => {
        fetchReviews()
    }, [])

    useEffect(() => {
        fetchFavorites()
    }, [])

    function fetchFavorites() {
        Root.collegeApi.getFavorites(session.id).then((res) => {
            setFavorites(res.data)
        }).catch(() => {
            setFavorites([])
        })
    }

    function reduceRatings(ratings){
        // need to clone first bc when you reduce on ratings, it refs the same obj each time and alters vals
          const cloneRatings = ratings.map(a => Object.assign({}, a));
          if(cloneRatings.length < 1){
              return {}
          }
          const reducer = (accumulator, currentValue) => {
              Object.keys(fields).forEach( key => {
              accumulator[key] += currentValue[key]
              }
          )
              return accumulator;
          };
          return cloneRatings.reduce(reducer);
    }

    function getAverageTotalRating(ratings){
        if(ratings.length < 1){
            return null
        }
        var reducedRatings = reduceRatings(ratings)
        var total = 0

        Object.keys(reducedRatings).forEach(key => {
          if(Object.keys(fields).includes(key)){
            total += (reducedRatings[key]/ratings.length)
          }
        })
        return (total / 12).toFixed(1)
    }

    function fetchReviews () {
        Root.collegeApi.getCollegeReviews(id).then(rsp => {
            setReviews(rsp.data)
            setReducedRating(reduceRatings(rsp.data))
            setAverageRating(getAverageTotalRating(rsp.data))
        }).catch((err) => {setReviews([]);})
    }

    function isFavorite (id) {
        if (favorites) {
            return favorites.some(function (item) {
                return Object.values(item).includes(id)
            })
        }
        return false
    }

    function handleFavorite () {
        if (!isFavorite(id)) {
          Root.collegeApi.favorite(id, session.id).then((res) => {
            fetchFavorites()
          }).catch((err) => {
                console.log(err)
          })
          return
        } else {
            Root.collegeApi.unfavorite(id, session.id).then((res) => {
                fetchFavorites()
              }).catch((err) => {
                    console.log(err)
              })
        }
      }

  return <div className={s.university}>
        <SideBar session={session} title={"Colleges"} >
        {college && <div className={s.grid}>
            <div className={s.info}>
                <Card className={''}>
                    <Card.Body>
                        <CollegeImageLighbox contentList={images}>
                        <div className={s.thumbnailGrid}>
                            {images && <>
                                <img src={images[0] || placeholder} />
                                <img src={images[1] || placeholder} />
                                <img src={images[2] || placeholder} />
                            </>
                            }
                        </div>
                        </CollegeImageLighbox>
                        <Card.Title>{collegeName}
                           { isMentee(session) &&  <Button className='btnLink' onClick={handleFavorite}>
                                {isFavorite(id) ? <AiFillHeart className='purple' size={20}/> : <AiOutlineHeart className='purple' size={20}/>}
                            </Button> }
                        </Card.Title>
                        <Card.Subtitle>{college.city}, {college.state}</Card.Subtitle>
                            <a href={ college.website && `https://${urlToHostname(college.website)}`}>{college.website}</a>
                            <span className={`${s.green} mb-2 d-flex align-items-center`}>
                                {new Array(averageRating).map((s, idx) => {return <AiFillStar key={idx} color='#23CDAA'/> })}{' '}({averageRating || '-'})
                            </span>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h3 style={{fontSize:'24px'}} className={s.green}>
                                        <strong>
                                        {typeof college.isPublic === 'boolean'  ?
                                        <> {college.isPublic === true && 'Public'} {college.isPublic === false && 'Private'}</>
                                            : '-'}
                                        </strong></h3>
                                    <h6 style={{fontSize:'12px'}} className='text-muted'>College Type</h6>
                                </div>
                                <div className='ml-3'>
                                    <h3 style={{fontSize:'24px'}} className={s.green}><strong>{(college.mentorCount) ? college.mentorCount : '0'}</strong></h3>
                                    <h6 style={{fontSize:'12px'}} className='text-muted '>Mentors</h6>
                                </div>
                                <div className='ml-3'>
                                    <h3 style={{fontSize:'24px'}} className={s.green}>
                                        <strong>
                                        {(college.associates || college.bachelors) ?
                                        <>{ college.associates === true && college.bachelors === false && '2-YEAR'}{college.bachelors === true && '4-YEAR'}</> :
                                        '-'
                                        }
                                        </strong></h3>
                                    <h6 style={{fontSize:'12px'}} className='text-muted'>Degree Length</h6>
                                </div>
                            </div>
                            {session.id && isMentee(session) && <BookMeeting session={session} ipedsid={id}/>}
                        {session.id && isMentee(session) && <ChatNXSTEPAI session={session} sessionStartTime={sessionStartTime} ipedsid={id} collegeName={collegeName}/>}
                    </Card.Body>
                </Card>
            </div>
            <div className={s.tabs}>

                <Tabs className='mb-4 spaced'
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                <Tab eventKey="about" title={<span className='d-flex align-items-center'><span><span className={s.text}>About</span> {' '} <TiDocument size={20} className={`${s.green} ml-2`}/></span></span>}>
                    <About data={college}/>
                </Tab>
                <Tab eventKey="photos" title={<span className='d-flex align-items-center'><span><span className={s.text}>Photos</span> {' '} <AiOutlineCamera size={20} className={`${s.green} ml-2`}/></span></span>}>
                <HighlightReel session={session} ipedsid={id} highlights={highlightPhotos}/>
                </Tab>
                <Tab eventKey="videos" title={<span className='d-flex align-items-center'><span><span className={s.text}>Videos</span> {' '} <AiOutlineVideoCamera size={20} className={`${s.green} ml-2`}/></span></span>}>
                    <HighlightReel session={session} ipedsid={id} highlights={highlightVideos}/>
                </Tab>
                <Tab eventKey="review" title={<span className='d-flex align-items-center'><span><span className={s.text}>Review</span> {' '} <AiOutlineLike size={20} className={`${s.green} ml-2`}/></span></span>}>
                    <Review session={session} ipedsid={id} averageRating={averageRating} isMentor={isMentor(session)}
                    reviews={reviews} reducedRating={reducedRating} fetchReviews={fetchReviews}/>
                </Tab>
                </Tabs>

            </div>
        </div>}
   </SideBar>
</div>
}

export default (University);
