import React, {useContext, useEffect, useState} from 'react'
import s from './styles/Invites.module.scss'
import {isMentee, isMentor, SessionContext} from 'Utils/sessions';
import {Root} from 'Root'
import SideBar from 'Components/Nav/SideBar';
import avi from 'Static/default-avi.png'
import {push, validateEmail} from 'Utils/validation';
import {NoAutoFillTextInput} from 'Input/Input';
import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/Modal';
import {Link} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import {AiOutlineCopy} from 'react-icons/ai'
import {toast, ToastContainer} from "react-toastify";

const Invites = () => {
    const {session, refreshSession}  = useContext(SessionContext)
    const [referralStatistics, setReferralStatistics] = useState([])
    const [leaderboardRanking, setLeaderboardRanking] = useState(null)
    const [leaderboardEntries, setLeaderboardEntries] = useState([])
    const [leaderboardEntry, setLeaderboardEntry] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    const [formResponse, setFormResponse] = useState(null)
    const [formResponseError, setFormResponseError] = useState(null)
    const [request, setRequest] = useState({})
    const [show, setShow] = useState(false)
    
    useEffect(()=> {
        getLeaderboardEntries()
    },[])

    useEffect(()=> {
        getLeaderboardRanking()
    },[])

    useEffect(()=> {
        getReferralStatistics()
    },[])

    function handleChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, [name]: value }));
    }

    function focusLeaderEntry(referrer) {
        setShow(true);
        setLeaderboardEntry(referrer)
    }


    function validate (request) {
        var errors = {}
        if (!validateEmail(request.email)) {
            push(errors, 'email', 'You must provide a valid email for your invitation')
        }
        return errors
    }

    function createReferral() {
        setFormErrors({});
        setFormResponse(null);
        setFormResponseError(null);
        const errors = validate(request)
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return
        }
        if(Object.keys(request).length > 0){
            Root.miscApi.sendStudentReferral(request.email, session.id, 'NXSTEP').then((res) => {
                setFormResponse("Success! Your invitation has been sent.")
                getLeaderboardEntries()
                getLeaderboardRanking()
                getReferralStatistics()
                setRequest({});
                setFormErrors({});
            }).catch((res) => {
                    console.log(res)
                    setFormResponseError(res.data || "Unable to send a referral to this email at this time.");
                }
            )
        }
    }

    function getLeaderboardEntries() {
        Root.miscApi.getLeaderboardEntries('NXSTEP').then((res) => {
            setLeaderboardEntries(res.data)
        }).catch((res) => {})
    }

    function getLeaderboardRanking() {
        Root.miscApi.getLeaderboardRanking(session.id, 'NXSTEP').then((res) => {
            setLeaderboardRanking(res.data)
        }).catch((res) => {})
    }

    function getReferralStatistics() {
        Root.miscApi.getReferralStatistics(session.id, 'NXSTEP').then((res) => {
            setReferralStatistics(res.data)
        }).catch((res) => {})
    }

    const Tile = ({t}) => {
        const userId = session.id || session.userId
        const [show, setShow] = useState(false)
        const [entry, setEntry] = useState(false)

        if(! t.referrer || !t.referralCount)
            return <></>

        return  <div className={`${s.threadTile}`} onClick={() => {focusLeaderEntry(t.referrer)}}>
            <img src={t.referrer.photo || avi}/>
            <span className='ms-2'>
      <p className='mb-0 fs-14'>{t.referrer.firstName} {t.referrer.lastName}</p>
      <p className='mb-0 fs-14 text-muted'>{t.referralCount} joined invites</p>
    </span>
        </div>
    }
    return <div className={s.media}>
        <SideBar session={session} title={"Invites"}>
            {leaderboardEntry && <Modal show={show} onHide={() => {setShow(false)}}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <img className="d-block mx-auto" src={leaderboardEntry.photo || avi} style={{height:'70px', width:'70px', objectFit:'cover', borderRadius:'50%' }} />
                    {isMentor(leaderboardEntry) ? <Link to={`/profile/student/${leaderboardEntry.userName}`} className='btnLink ms-2'><p className="text-center mt-1 mb-0 pb-0">{leaderboardEntry.firstName} {leaderboardEntry.lastName}</p></Link> : <p className="text-center mt-1 mb-0 pb-0">{leaderboardEntry.firstName} {leaderboardEntry.lastName}</p>}
                    {isMentee(leaderboardEntry) && <p className='fs-13 text-muted text-center'>Prospective College Student</p>}
                    {leaderboardEntry.studentType === "EMPLOYEE" && leaderboardEntry.company && <p className='fs-13 text-muted text-center'>Employee at {leaderboardEntry.company.name}</p>}
                    <p className='text-center fs-14'>{leaderboardEntry.bio}</p>
                </Modal.Body>
            </Modal>}

            <div className={`${s.grid} ${s.web}`} style={{gridTemplateColumns: '70% 30%'}}>
                <div className={s.thread}>
                    <div className={s.threadContent}>
                        <h3>Invite your friends. Get rewarded. ✨</h3>
                        <p style={{fontSize: '14px', marginTop: '12px'}}>Each friend you invite that joins
                            counts towards exclusive prizes!</p>
                    </div>

                    <div className={s.threadContent}>
                        <div className={s.row}>
                            <h3 className='heading'>Your Statistics</h3>
                            <p style={{fontSize: '12px'}}>Current
                                ranking: {leaderboardRanking ? (leaderboardRanking === 0 ? 'None yet' : leaderboardRanking) : 'None yet'}</p>
                            <div className={s.statusGrid} style={{marginTop: '20px'}}>
                                <Card className={s.statusCard}>
                                    <Card.Body>
                                        <span className='fs-14'> Total Invited </span>
                                        <div style={{textAlign: 'center', marginTop: '12px'}}>
                                            <h2 className='purple d-inline'>{referralStatistics.invited ? referralStatistics.invited : '-'}</h2>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className={s.statusCard}>
                                    <Card.Body>
                                        <span className='fs-14'>Total Joined</span>
                                        <div style={{textAlign: 'center', marginTop: '12px'}}>
                                            <h2 className='purple d-inline'>{referralStatistics.joined ? referralStatistics.joined : '-'}</h2>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div className={s.threadContent} style={{marginBottom: '75px'}}>
                        <div style={{width: '75%'}}>
                            <h4>Send a direct email:</h4>
                            <Form>
                                <NoAutoFillTextInput errors={formErrors} name={'email'}
                                                     value={request.email || ''} onChange={handleChange}
                                                     type={'text'} placeholder={"Invitee's Email"}/>
                                <div style={{float: 'left'}}>
                                    <Button className={'btnFill sm sm mt-3'} onClick={createReferral}>Send
                                        Invitation</Button>
                                </div>
                                <div className={'form-error'} style={{
                                    float: "left",
                                    paddingTop: "15px"
                                }}>{formResponse || formResponseError || formErrors.description || null}</div>
                            </Form>
                        </div>
                    </div>
                    <div className={s.threadContent}>
                        <div style={{width: '75%', paddingBottom: '25px'}}>
                            {session.referralLink ? <h4>Share your unique link:</h4> :
                                <h4>Login for your unique referral link:</h4>}
                            <Card className='xlCard'>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='mb-0 text-muted' style={{
                                            display: "flex",
                                            justifyContent: 'flex-start',
                                            height: '24px',
                                            width: '90%',
                                            overflow: 'hidden'
                                        }}>{session.referralLink ? `https://nxstep.co/referrals/generic/${session?.referralLink}` : `https://nxstep.co/referrals/generic`}</p>
                                        <span className='text-muted d-block'>
                                                        <Button className='btnLink' onClick={() => {
                                                            if (session.referralLink) {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/generic/${session?.referralLink}`);
                                                            } else {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/generic`);
                                                            }
                                                            toast("Copied!")
                                                        }}><AiOutlineCopy style={{fontSize: '200%'}}/></Button>
                                                    </span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className={`${s.threads}`}>
                    <h3>Leaderboard</h3>
                    <hr className='my-0'/>
                    <div className={s.threadsContainer}>
                        {(leaderboardEntries.length == 0) &&
                            <div className={`${s.threadTile}`}>
                                <span className='ms-2'>
                                  <p className='mb-0 fs-14'>It's too early to call it yet! Keep referring, and
                                          check back later :)</p>
                                </span>
                            </div>}
                        {leaderboardEntries.map((t, idx) => {
                            return <>
                                <Tile t={t}/>
                                <hr className='my-0'/>
                            </>
                        })}
                    </div>
                </div>
            </div>

            <div className={`${s.horizontalGrid} ${s.mobile}`}>
                <div className={s.thread}>
                    <div className={s.threadContent}>
                        <h3>Invite your friends. Get rewarded. ✨</h3>
                        <p style={{fontSize: '14px', marginTop: '12px'}}>Each friend you invite that joins
                            counts
                            towards exclusive prizes!</p>
                    </div>

                    <div className={s.threadContent}>
                        <div className={s.row}>
                            <h3 className='heading'>Your Statistics</h3>
                            <p style={{fontSize: '12px'}}>Current
                                ranking: {leaderboardRanking ? (leaderboardRanking == 0 ? 'None yet' : leaderboardRanking) : 'None yet'}</p>
                            <div className={s.statusGrid} style={{marginTop: '20px'}}>
                                <Card className={s.statusCard}>
                                    <Card.Body>
                                        <span className='fs-14'> Total Invited </span>
                                        <div style={{textAlign: 'center', marginTop: '12px'}}>
                                            <h2 className='purple d-inline'>{referralStatistics.invited ? referralStatistics.invited : '0'}</h2>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className={s.statusCard}>
                                    <Card.Body>
                                        <span className='fs-14'>Total Joined</span>
                                        <div style={{textAlign: 'center', marginTop: '12px'}}>
                                            <h2 className='purple d-inline'>{referralStatistics.joined ? referralStatistics.joined : '0'}</h2>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className={s.threadContent} style={{marginBottom: '15px'}}>
                        <div>
                            <h4>Send a direct email:</h4>
                            <Form>
                                <NoAutoFillTextInput errors={formErrors} name={'email'}
                                                     value={request.email || ''} onChange={handleChange}
                                                     type={'text'} placeholder={"Their Email"}/>
                                <div style={{float: 'left'}}>
                                    <Button className={'btnFill sm sm mt-3'} onClick={createReferral}>Send
                                        Invitation</Button>
                                </div>
                                <div className={'form-error'} style={{
                                    float: "left",
                                    paddingTop: "15px"
                                }}>{formResponse || formResponseError || formErrors.description || null}</div>
                            </Form>
                        </div>
                    </div>

                    <div className={s.threadContent}>
                        <div style={{paddingBottom: '25px'}}>
                            <h4>Share your unique link:</h4>
                            <Card className='xlCard'>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className={`mb-0 text-muted ${s.sharedLink}`}>{session.referralLink ? `https://nxstep.co/referrals/generic/${session?.referralLink}` : `https://nxstep.co/referrals/generic`}</p>
                                        <span className='text-muted d-block'>
                                                        <Button className='btnLink' onClick={() => {
                                                            if (session.referralLink) {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/generic/${session?.referralLink}`);
                                                            } else {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/generic`);
                                                            }
                                                            toast("Copied!")
                                                        }}><AiOutlineCopy style={{fontSize: '200%'}}/></Button>
                                                  </span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

                <div className={`${s.threads}`}>
                    <h3>Leaderboard</h3>
                    <hr className='my-0'/>
                    <div className={s.threadsContainer}>
                        {(leaderboardEntries.length == 0) &&
                            <div className={`${s.threadTile}`}>
                <span className='ms-2'>
                  <p className='mb-0 fs-14'>It's too early to call it yet! Keep referring, and check back later
                          :)</p>
                </span>
                            </div>}
                        {leaderboardEntries.map((t, idx) => {
                            return <>
                                <Tile t={t}/>
                                <hr className='my-0'/>
                            </>
                        })}
                    </div>
                </div>
            </div>
        </SideBar>
        <ToastContainer theme="dark" autoClose={2000} position="bottom-center"/>
    </div>
}

export default (Invites);
