import React, { useContext, useState, useEffect } from 'react'
import s from './styles/Ambassadors.module.scss'
import { SessionContext, isMentor, isMentee } from 'Utils/sessions';
import { FaInfoCircle } from 'react-icons/fa';
import {Root} from 'Root'
import SideBar from 'Components/Nav/SideBar';
import avi from 'Static/default-avi.png'
import { validateEmail, push } from 'Utils/validation';
import { NoAutoFillTextInput, TextInput } from 'Input/Input';
import Button from 'react-bootstrap/esm/Button'
import Spinner from 'react-bootstrap/esm/Spinner';
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import {AiOutlineCopy} from 'react-icons/ai'
import {toast, ToastContainer} from "react-toastify";
import {clearSessionCookie, setSessionCookie} from "../../Utils/sessions";
import {getCurrentAmbassadorProgram, logPageLanding} from "../../Api/Utils/referral";

const Tooltip = ({ text, children }) => {
    return (
        <div className={s.tooltip}>
            {children}
            <span className={s.tooltipText}>{text}</span>
        </div>
    );
};

const Ambassadors = () => {
    const useQuery = () => { return new URLSearchParams(useLocation().search) }
    const {session, refreshSession}  = useContext(SessionContext)
    const [formErrors, setFormErrors] = useState({})
    const [formResponse, setFormResponse] = useState(null)
    const [formResponseError, setFormResponseError] = useState(null)
    const [request, setRequest] = useState({})
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const accessCode = useQuery().get('ac')

    const [ambassadorProgram, setAmbassadorProgram] = useState(null);
    const [ambassadorData, setAmbassadorData] = useState(null);
    const [leaderboardRanking, setLeaderboardRanking] = useState(null)
    const [leaderboardEntries, setLeaderboardEntries] = useState([])
    const [leaderboardEntry, setLeaderboardEntry] = useState(null)

    useEffect(() => {
        if (accessCode && accessCode.length > 0) {
            request.passkey = accessCode;
        }
    }, []);

    useEffect(()=> {
        getCurrentAmbassadorProgram().then((res) => {
            if (res.status === 200) {
                setAmbassadorProgram(res.data);
            }
        })
    },[])

    useEffect(()=> {
        getLeaderboardEntries()
    },[])

    useEffect(()=> {
        getLeaderboardRanking()
    },[])

    useEffect(()=> {
        const currentSessionStartTime = new Date().getTime();
        if (session.ambassadorData && ((currentSessionStartTime - session.timestamp) < /* 1 day */ 86400000)) {
            logPageLanding("ambassador", session.ambassadorData?.userId || accessCode || 'unknown')
            getAmbassadorData()
        } else {
            setAmbassadorData({});
            session.ambassadorData = {};
            logPageLanding("ambassador", accessCode || 'unknown')
            setSessionCookie(session)
        }
    },[])

    function handleChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, [name]: value }));
    }

    function focusLeaderEntry(referrer) {
        setShow(true);
        setLeaderboardEntry(referrer)
    }


    function validate (request) {
        var errors = {}
        if (!validateEmail(request.email)) {
            push(errors, 'email', 'You must provide a valid email for your invitation')
        }
        return errors
    }

    function createReferral() {
        setFormErrors({});
        setFormResponse(null);
        setFormResponseError(null);
        const errors = validate(request)
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return
        }
        if(Object.keys(request).length > 0){
            setLoading(true);
            Root.miscApi.sendAmbassadorReferral(request.email, ambassadorData.userId, ambassadorProgram?.name || 'generic').then((res) => {
                setLoading(false);
                toast("Success! Your invitation has been sent.")
                getLeaderboardEntries()
                getLeaderboardRanking()
                getAmbassadorData()
                setRequest({});
                setFormErrors({});
            }).catch((res) => {
                    setLoading(false);
                    console.log(res)
                    setFormResponseError(res.data || "Unable to send a referral to this email at this time.");
                }
            )
        }
    }

    function getLeaderboardEntries() {
        Root.miscApi.getLeaderboardEntries('NXSTEP').then((res) => {
            setLeaderboardEntries(res.data)
        }).catch((res) => {})
    }

    function getLeaderboardRanking() {
        Root.miscApi.getLeaderboardRanking(session.id, 'NXSTEP').then((res) => {
            setLeaderboardRanking(res.data)
        }).catch((res) => {})
    }

    function getAmbassadorData() {
        if (ambassadorData?.userId || session.ambassadorData?.userId) {
            Root.miscApi.getAmbassadorData(ambassadorData?.userId ||session.ambassadorData?.userId)
                .then((res) => {
                    setAmbassadorData(res.data)
                    setSessionCookie({ambassadorData: res.data, timestamp: new Date().getTime()});
                })
                .catch((err) => {});
        }
    }

    function validateAuthentication (request) {
        let errors = {}
        if (!request.identifier) {
            push(errors, 'identifier', 'You must provide a valid email or phone')
        }

        if (!request.passkey) {
            push(errors, 'passkey', 'You must provide an access code')
        }
        return errors
    }

    function authenticateAffiliate() {
        setFormResponseError(null);
        setFormErrors({})
        const errors = validateAuthentication(request)
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return
        }

        setLoading(true);

        Root.miscApi.authenticateAmbassador(request.identifier, request.passkey)
            .then((res) => {
                setLoading(false);
                setAmbassadorData(res.data);
                setSessionCookie({ambassadorData: res.data, timestamp: new Date().getTime()});
            }).catch((res) => {
            setLoading(false);
            setFormResponseError(res.data || "Unable to authenticate")
        })
    }

    function collectName() {
        setFormErrors({});
        let errors = {}
        if (!request.firstName || request.firstName.length === 0) {
            push(errors, 'firstName', 'You must provide a first name')
            setFormErrors(errors);
            return;
        }

        if (!request.lsatName || request.lsatName.length === 0) {
            push(errors, 'lsatName', 'You must provide a last name')
            setFormErrors(errors);
            return;
        }

        ambassadorData.firstName = request.firstName;
        ambassadorData.lastName = request.lastName;
    }

    function updateAmbassador() {
        setLoading(true);
        Root.miscApi.updateAmbassador(ambassadorData.userId, ambassadorData.firstName, ambassadorData.lastName, true)
            .then((res) => {
                setLoading(false);
                setSessionCookie({ambassadorData: res.data, timestamp: new Date().getTime()});
                setAmbassadorData(res.data);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    const Tile = ({t}) => {
        const userId = session.id || session.userId
        const [show, setShow] = useState(false)
        const [entry, setEntry] = useState(false)

        if(! t.referrer || !t.referralCount)
            return <></>

        return  <div className={`${s.threadTile}`} onClick={() => {focusLeaderEntry(t.referrer)}}>
            <img src={t.referrer.photo || avi}/>
            <span className='ms-2'>
      <p className='mb-0 fs-14'>{t.referrer.firstName} {t.referrer.lastName}</p>
      <p className='mb-0 fs-14 text-muted'>{t.referralCount} joined invites</p>
    </span>
        </div>
    }
    return <div className={s.media}>
        <SideBar session={session} title={"Partners"}>
            {leaderboardEntry && <Modal show={show} onHide={() => {setShow(false)}}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <img className="d-block mx-auto" src={leaderboardEntry.photo || avi} style={{height:'70px', width:'70px', objectFit:'cover', borderRadius:'50%' }} />
                    {isMentor(leaderboardEntry) ? <Link to={`/profile/student/${leaderboardEntry.userName}`} className='btnLink ms-2'><p className="text-center mt-1 mb-0 pb-0">{leaderboardEntry.firstName} {leaderboardEntry.lastName}</p></Link> : <p className="text-center mt-1 mb-0 pb-0">{leaderboardEntry.firstName} {leaderboardEntry.lastName}</p>}
                    {isMentee(leaderboardEntry) && <p className='fs-13 text-muted text-center'>Prospective College Student</p>}
                    {leaderboardEntry.studentType === "EMPLOYEE" && leaderboardEntry.company && <p className='fs-13 text-muted text-center'>Employee at {leaderboardEntry.company.name}</p>}
                    <p className='text-center fs-14'>{leaderboardEntry.bio}</p>
                </Modal.Body>
            </Modal>}

            <div className={`${s.grid} ${s.web}`} style={{gridTemplateColumns: (ambassadorData?.userId && ambassadorData?.tos && ambassadorProgram?.type === 'competition') ? '70% 30%' : '100% 0%' }}>
                <div className={s.thread}>
                    {ambassadorData?.userId && ambassadorData?.tos ?
                        <>
                            <div className={s.threadContent}>
                                <h3>{ambassadorProgram?.title ? ambassadorProgram?.title : 'Invite your friends. Get rewarded. ✨'}</h3>
                                <p style={{fontSize: '14px', marginTop: '12px'}}>
                                    {ambassadorProgram?.subtitle ? ambassadorProgram?.subtitle
                                        : 'Each friend you invite that joins counts towards cash and exclusive prizes!'}</p>
                                {ambassadorProgram?.description && <p style={{
                                    fontSize: '14px',
                                    marginTop: '12px'
                                }}>{ambassadorProgram?.description}</p>}
                                {ambassadorProgram?.url &&
                                    <p style={{fontSize: '14px', marginTop: '12px'}}>For more information visit: <a
                                        href={ambassadorProgram?.url} target={'_blank'}
                                        rel={'noreferrer'}>{ambassadorProgram?.url.replace('https://', '')}</a></p>}
                            </div>

                            <div className={s.threadContent}>
                                <div className={s.row}>
                                    <h3 className='heading'>Your Statistics</h3>
                                    <p style={{fontSize: '12px'}}>Current
                                        ranking: {leaderboardRanking ? (leaderboardRanking === 0 ? 'None yet' : leaderboardRanking) : 'None yet'}</p>
                                    <div className={s.statusGrid} style={{marginTop: '20px'}}>
                                        <Card className={s.statusCard}>
                                            <Card.Body>
                                                <Tooltip text="Total number of pending invitations">
                                                    <span className='fs-14'>Pending Invitations <FaInfoCircle/></span>
                                                </Tooltip>
                                                <div style={{textAlign: 'center', marginTop: '12px'}}>
                                                    <h2 className='purple d-inline'>{ambassadorData?.outstandingReferrals ? ambassadorData?.outstandingReferrals : '0'}</h2>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <Card className={s.statusCard}>
                                            <Card.Body>
                                                <Tooltip text="Total number of your invitees who've joined NXSTEP">
                                                    <span className='fs-14'>Total Completed <FaInfoCircle/></span>
                                                </Tooltip>
                                                <div style={{textAlign: 'center', marginTop: '12px'}}>
                                                    <h2 className='purple d-inline'>{ambassadorData?.referrals ? ambassadorData?.referrals : '0'}</h2>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        {/*<Card className={s.statusCard}>*/}
                                        {/*    <Card.Body>*/}
                                        {/*        <Tooltip text="Total amount you've earned from referrals. This does not include referrals towards prizes.">*/}
                                        {/*            <span className='fs-14'>Total Compensation <FaInfoCircle /></span>*/}
                                        {/*        </Tooltip>*/}
                                        {/*        <div style={{textAlign: 'center', marginTop: '12px'}}>*/}
                                        {/*            <h2 className='purple d-inline'>${ambassadorData?.compensation ? ambassadorData?.compensation : '0'}</h2>*/}
                                        {/*        </div>*/}
                                        {/*    </Card.Body>*/}
                                        {/*</Card>*/}
                                    </div>
                                </div>
                            </div>

                            <div className={s.threadContent} style={{marginBottom: '75px'}}>
                                <div style={{width: '75%'}}>
                                    <h4>Send a direct email:</h4>
                                    <Form>
                                        <NoAutoFillTextInput errors={formErrors} name={'email'}
                                                             value={request.email || ''} onChange={handleChange}
                                                             type={'text'} placeholder={"Their email"}/>
                                        <div style={{float: 'left'}}>
                                            <Button className={'btnFill sm sm mt-3'}
                                                    style={{background: loading ? "grey" : ""}}
                                                    onClick={createReferral}>{loading ?
                                                <Spinner style={{color: "white"}} animation="border"
                                                         role="status"/> : "Send Invitation"}</Button>
                                        </div>
                                        <div className={'form-error'} style={{
                                            float: "left",
                                            paddingTop: "15px"
                                        }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                    </Form>
                                </div>
                            </div>

                            <div className={s.threadContent}>
                                <div style={{width: '75%', paddingBottom: '25px'}}>
                                    <h4>Copy and share your unique link:</h4>
                                    <Card className='xlCard'>
                                        <Card.Body>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='mb-0 text-muted' style={{
                                                    display: "flex",
                                                    justifyContent: 'flex-start',
                                                    height: '24px',
                                                    width: '90%',
                                                    overflow: 'hidden'
                                                }}>{ambassadorData?.referralLink ? `https://nxstep.co/referrals/partners/${ambassadorData?.referralLink}` : `https://nxstep.co/referrals/generic`}</p>
                                                <span className='text-muted d-block'>
                                                        <Button className='btnLink' onClick={() => {
                                                            if (ambassadorData?.referralLink) {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/partners/${ambassadorData?.referralLink}`);
                                                            } else {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/generic`);
                                                            }
                                                            toast("Copied!")
                                                        }}><AiOutlineCopy style={{fontSize: '200%'}}/></Button>
                                                    </span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {ambassadorData?.userId ?
                                <>
                                    <div className={s.login}>
                                        <div className={s.form}>
                                            {ambassadorData?.firstName ?
                                                <>
                                                    <h2>Privacy and Terms</h2>
                                                    <p style={{
                                                        fontSize: '16px',
                                                        textAlign: 'center',
                                                        marginTop: '10px'
                                                    }}>By continuing, you are now joining NXSTEP's Ambassador program.
                                                        To withdraw your membership or for any questions, reach out to
                                                        us on our <a href={'/contact'} target={'_blank'}
                                                                     rel={'noreferrer'}>Contact</a> page.</p>

                                                    <p style={{
                                                        fontSize: '16px',
                                                        textAlign: 'center',
                                                        marginTop: '10px'
                                                    }}>By continuing, you also acknowledge that you
                                                        have read our <a href={'/privacy'} target={'_blank'}
                                                                         rel={"noreferrer"}>privacy policy</a> and agree
                                                        to receive SMS & MMS notifications from NXSTEP.
                                                        Message frequency varies. Msg. & Data Rates apply. You can
                                                        opt-out of messages at anytime in the settings
                                                        or by replying with 'STOP'.</p>
                                                    <Button onClick={() => updateAmbassador()} disabled={loading}
                                                            style={{background: loading ? "grey" : ""}}
                                                            className="btnFill md d-block">{loading ?
                                                        <Spinner style={{color: "white"}} animation="border"
                                                                 role="status"/> : "Continue"}</Button>
                                                    <div className={'form-error'} style={{
                                                        float: "left",
                                                        paddingTop: "15px"
                                                    }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                                </>
                                                :
                                                <>
                                                    <h2>Tell us more about you</h2>
                                                    <Form>
                                                        <TextInput
                                                            containerClassName='mb-3'
                                                            errors={formErrors} name={'firstName'}
                                                            value={request.firstName} onChange={handleChange}
                                                            placeholder={'First Name'}/>
                                                        <TextInput
                                                            containerClassName='mb-3'
                                                            errors={formErrors} name={'lastName'}
                                                            value={request.lastName} onChange={handleChange}
                                                            placeholder={'Last Name'}/>
                                                    </Form>
                                                    <Button onClick={() => collectName()} disabled={loading}
                                                            style={{background: loading ? "grey" : ""}}
                                                            className="btnFill md d-block">{loading ?
                                                        <Spinner style={{color: "white"}} animation="border"
                                                                 role="status"/> : "Next"}</Button>
                                                    <div className={'form-error'} style={{
                                                        float: "left",
                                                        paddingTop: "15px"
                                                    }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                                </>}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={s.login}>
                                        <div className={s.form}>
                                            <h2>Enter your credentials</h2>
                                            <Form>
                                                <NoAutoFillTextInput errors={formErrors} name={'identifier'}
                                                                     containerClassName='mb-3'
                                                                     value={request.identifier || ''}
                                                                     onChange={handleChange}
                                                                     type={'text'} placeholder={"Email or phone"}/>
                                                <NoAutoFillTextInput errors={formErrors} name={'passkey'}
                                                                     containerClassName='mb-3'
                                                                     value={request.passkey || ''}
                                                                     onChange={handleChange}
                                                                     type={'text'} placeholder={"Access code"}/>
                                            </Form>
                                            <p>
                                                Have a student account? {' '}
                                                <a className='btnLink' href="/login">Login instead</a>
                                            </p>
                                            <Button onClick={authenticateAffiliate} disabled={loading}
                                                    style={{background: loading ? "grey" : ""}}
                                                    className="btnFill md d-block">{loading ?
                                                <Spinner style={{color: "white"}} animation="border"
                                                         role="status"/> : "Continue"}</Button>
                                            <div className={'form-error'} style={{
                                                float: "left",
                                                paddingTop: "15px"
                                            }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                        </div>
                                    </div>
                                </>}
                        </>
                    }
                </div>
                {ambassadorData?.userId && ambassadorData?.tos && ambassadorProgram?.type === 'competition' &&
                    <div className={`${s.threads}`}>
                        <h3>Leaderboard</h3>
                        <hr className='my-0'/>
                        <div className={s.threadsContainer}>
                            {(leaderboardEntries.length === 0) &&
                                <div className={`${s.threadTile}`}>
                                <span className='ms-2'>
                                  {ambassadorData?.userId ?
                                      <><p className='mb-0 fs-14'>It's too early to call it yet! Keep referring, and
                                          check back later :)</p></>
                                      :
                                      <><p className='mb-0 fs-14'>Login to view the rankings :)</p></>
                                  }
                                </span>
                                </div>}
                            {ambassadorData?.userId && leaderboardEntries.map((t, idx) => {
                                return <>
                                    <Tile t={t}/>
                                    <hr className='my-0'/>
                                </>
                            })}
                        </div>
                    </div>
                }
            </div>

            <div className={`${s.horizontalGrid} ${s.mobile}`}>
                <div className={s.thread}>
                    {(ambassadorData?.userId && ambassadorData?.tos) ?
                        <>
                            <div className={s.threadContent}>
                                <h3>{ambassadorProgram?.title ? ambassadorProgram?.title : 'Invite your friends. Get rewarded. ✨'}</h3>
                                <p style={{fontSize: '14px', marginTop: '12px'}}>{ambassadorProgram?.subtitle
                                    ? ambassadorProgram?.subtitle : 'Each friend you invite that joins counts towards exclusive prizes!'}</p>
                                {ambassadorProgram?.description && <p style={{
                                    fontSize: '14px',
                                    marginTop: '12px'
                                }}>{ambassadorProgram?.description}</p>}
                                {ambassadorProgram?.url &&
                                    <p style={{fontSize: '14px', marginTop: '12px'}}>For more information visit: <a
                                        href={ambassadorProgram?.url} target={'_blank'}
                                        rel={'noreferrer'}>{ambassadorProgram?.url.replace('https://', '')}</a></p>}
                            </div>

                            <div className={s.threadContent}>
                                <div className={s.row}>
                                    <h3 className='heading'>Your Statistics</h3>
                                    <p style={{fontSize: '12px'}}>Current
                                        ranking: {leaderboardRanking ? (leaderboardRanking === 0 ? 'None yet' : leaderboardRanking) : 'None yet'}</p>
                                    <div className={s.statusGrid} style={{marginTop: '20px'}}>
                                        <Card className={s.statusCard}>
                                            <Card.Body>
                                                <Tooltip text="Total number of pending invitations">
                                                    <span className='fs-14'>Pending Invitations <FaInfoCircle/></span>
                                                </Tooltip>
                                                <div style={{textAlign: 'center', marginTop: '12px'}}>
                                                    <h2 className='purple d-inline'>{ambassadorData?.outstandingReferrals ? ambassadorData?.outstandingReferrals : '0'}</h2>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <Card className={s.statusCard}>
                                            <Card.Body>
                                                <Tooltip text="Total number of your invitees who've joined NXSTEP">
                                                    <span className='fs-14'>Total Completed<FaInfoCircle/></span>
                                                </Tooltip>
                                                <div style={{textAlign: 'center', marginTop: '12px'}}>
                                                    <h2 className='purple d-inline'>{ambassadorData?.referrals ? ambassadorData?.referrals : '0'}</h2>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    {/*<div className={s.statusGridSolo} style={{marginTop: '20px'}}>*/}
                                    {/*    <Card className={s.statusCard}>*/}
                                    {/*        <Card.Body>*/}
                                    {/*            <Tooltip text="Total amount you've earned from referrals. This does not include referrals towards prizes.">*/}
                                    {/*                <span className='fs-14'>Total Compensation <FaInfoCircle /></span>*/}
                                    {/*            </Tooltip>*/}
                                    {/*            <div style={{textAlign: 'center', marginTop: '12px'}}>*/}
                                    {/*                <h2 className='purple d-inline'>${ambassadorData?.compensation ? ambassadorData?.compensation : '0'}</h2>*/}
                                    {/*            </div>*/}
                                    {/*        </Card.Body>*/}
                                    {/*    </Card>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <div className={s.threadContent} style={{marginBottom: '25px'}}>
                                <div>
                                    <h4>Send a direct email:</h4>
                                    <Form>
                                        <NoAutoFillTextInput errors={formErrors} name={'email'}
                                                             value={request.email || ''} onChange={handleChange}
                                                             type={'text'} placeholder={"Their email"}/>
                                        <div style={{float: 'left'}}>
                                            <Button className={'btnFill sm sm mt-3'} onClick={createReferral}>Send
                                                Invitation</Button>
                                        </div>
                                        <div className={'form-error'} style={{
                                            float: "left",
                                            paddingTop: "15px"
                                        }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                    </Form>
                                </div>
                            </div>

                            <div className={s.threadContent}>
                                <div style={{paddingBottom: '15px'}}>
                                    <h4>Copy and share your unique link:</h4>
                                    <Card className='xlCard'>
                                        <Card.Body>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className={`mb-0 text-muted ${s.sharedLink}`}>{ambassadorData?.referralLink ? `https://nxstep.co/referrals/partners/${ambassadorData?.referralLink}` : `https://nxstep.co/referrals/generic`}</p>
                                                <span className='text-muted d-block'>
                                                        <Button className='btnLink' onClick={() => {
                                                            if (ambassadorData?.referralLink) {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/partners/${ambassadorData?.referralLink}`);
                                                            } else {
                                                                navigator.clipboard.writeText(`https://nxstep.co/referrals/generic`);
                                                            }
                                                            toast("Copied!")
                                                        }}><AiOutlineCopy style={{fontSize: '200%'}}/></Button>
                                                  </span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </>

                        :

                        <>
                            {ambassadorData?.userId ?
                                <>
                                    <div className={s.login}>
                                        <div className={s.form}>
                                            {ambassadorData?.firstName ?
                                                <>
                                                    <h2>Privacy and Terms</h2>
                                                    <p>By continuing, you are now joining NXSTEP's Ambassador program.
                                                        To withdraw your membership or for any questions, reach out to
                                                        us on our <a href={'/contact'} target={'_blank'}
                                                                     rel={'noreferrer'}>Contact</a> page.</p>
                                                    <p>By continuing, you also acknowledge that you
                                                        have read our <a href={'/privacy'} target={'_blank'}
                                                                         rel={"noreferrer"}>privacy policy</a> and agree
                                                        to receive SMS & MMS notifications from NXSTEP.
                                                        Message frequency varies. Msg. & Data Rates apply. You can
                                                        opt-out of messages at anytime in the settings
                                                        or by replying with 'STOP'.</p>
                                                    <Button onClick={() => updateAmbassador()} disabled={loading}
                                                            style={{background: loading ? "grey" : ""}}
                                                            className="btnFill md d-block">{loading ?
                                                        <Spinner style={{color: "white"}} animation="border"
                                                                 role="status"/> : "Continue"}</Button>
                                                    <div className={'form-error'} style={{
                                                        float: "left",
                                                        paddingTop: "15px"
                                                    }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                                </>
                                                :
                                                <>
                                                    <h2>Tell us more about you</h2>
                                                    <Form>
                                                        <TextInput
                                                            containerClassName='mb-3'
                                                            errors={formErrors} name={'firstName'}
                                                            value={request.firstName} onChange={handleChange}
                                                            placeholder={'First Name'}/>
                                                        <TextInput
                                                            containerClassName='mb-3'
                                                            errors={formErrors} name={'lastName'}
                                                            value={request.lastName} onChange={handleChange}
                                                            placeholder={'Last Name'}/>
                                                    </Form>
                                                    <Button onClick={() => collectName()} disabled={loading}
                                                            style={{background: loading ? "grey" : ""}}
                                                            className="btnFill md d-block">{loading ?
                                                        <Spinner style={{color: "white"}} animation="border"
                                                                 role="status"/> : "Next"}</Button>
                                                    <div className={'form-error'} style={{
                                                        float: "left",
                                                        paddingTop: "15px"
                                                    }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                                </>}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={s.login}>
                                        <div className={s.form}>
                                            <h2>Enter your credentials</h2>
                                            <Form>
                                                <NoAutoFillTextInput errors={formErrors} name={'identifier'}
                                                                     containerClassName='mb-3'
                                                                     value={request.identifier || ''}
                                                                     onChange={handleChange}
                                                                     type={'text'} placeholder={"Email or phone"}/>
                                                <NoAutoFillTextInput errors={formErrors} name={'passkey'}
                                                                     containerClassName='mb-3'
                                                                     value={request.passkey || ''}
                                                                     onChange={handleChange}
                                                                     type={'text'} placeholder={"Access code"}/>
                                            </Form>
                                            <p>
                                                Have a student account? {' '}
                                                <a className='btnLink' href="/login">Login instead</a>
                                            </p>
                                            <Button onClick={authenticateAffiliate} disabled={loading}
                                                    style={{background: loading ? "grey" : ""}}
                                                    className="btnFill md d-block">{loading ?
                                                <Spinner style={{color: "white"}} animation="border"
                                                         role="status"/> : "Continue"}</Button>
                                            <div className={'form-error'} style={{
                                                float: "left",
                                                paddingTop: "15px"
                                            }}>{formResponse || formResponseError || formErrors.description || null}</div>
                                        </div>
                                    </div>
                                </>}
                        </>
                    }
                </div>

                {ambassadorData?.userId && ambassadorData?.tos && ambassadorProgram?.type === 'competition' &&
                    <div className={`${s.threads}`}>
                        <h3>Leaderboard</h3>
                        <hr className='my-0'/>
                        <div className={s.threadsContainer}>
                            {(leaderboardEntries.length === 0) &&
                                <div className={`${s.threadTile}`}>
                <span className='ms-2'>
                  {ambassadorData?.userId ?
                      <><p className='mb-0 fs-14'>It's too early to call it yet! Keep referring, and check back later
                          :)</p></>
                      :
                      <><p className='mb-0 fs-14'>Login to view the rankings :)</p></>
                  }
                </span>
                                </div>}
                            {ambassadorData?.userId && leaderboardEntries.map((t, idx) => {
                                return <>
                                    <Tile t={t}/>
                                    <hr className='my-0'/>
                                </>
                            })}
                        </div>
                    </div>
                }
            </div>
        </SideBar>
        <ToastContainer theme="dark" autoClose={2000} position="bottom-center"/>
    </div>
}

export default (Ambassadors);
