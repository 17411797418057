import React, {useEffect, useState} from 'react';
import {NavBar} from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import s from './styles/Login.module.scss';
import {Link, useLocation} from 'react-router-dom'
import {
  capitalizeFLetter,
  cleanPhone,
  push,
  validateAge,
  validateDate,
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
  validateReferrer,
  validateUserName
} from 'Utils/validation'

import {DayInput, FormErrors, PhoneInput, SelectInput, TextInput, TypeAhead} from 'Input/Input'
import Form from 'react-bootstrap/Form'

import {collegeIds} from 'Utils/colleges'
import {Root} from 'Root'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "../Discover/styles/University.module.scss";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import {setSessionCookie} from "../../Utils/sessions";
import {logPageLanding} from "../../Api/Utils/referral";

const gradYears = {
  2020: '2020',
  2021: '2021',
  2022: '2022',
  2023: '2023',
  2024: '2024',
  2025: '2025',
  2026: '2026'
}


const Register = () => (
  <>
    <NavBar dark={true}/>
    <div className={s.login}>
      <div className={s.form}>
        <h2>Register</h2>
        <RegisterForm />
      </div>
    </div>
  </>
);

export default (Register);



const RegisterForm = ({}) => {
  const [request, setRequest] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [idx, setIdx] = useState(0)
  const [showMessagingSpeedBump, setShowMessagingSpeedBump] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [accessCode, setAccessCode] = useState(null)
  const [userCode, setUserCode] = useState(null)
  const [showSuccessPage, setShowSuccessPage] = useState(false)
  const [loading, setLoading] = useState(false)
  const useQuery = () => { return new URLSearchParams(useLocation().search) }
  const campaignId = useQuery().get('campaignId')
  const emailId = useQuery().get('emailId')
  const reference = useQuery().get('reference')
  const partner = useQuery().get('partner')

  useEffect(()=> {
    if (reference || partner) {
      setRequest(prevState => ({ ...prevState, ['referrer']: reference, ['partner']: partner }));
    }
  },[])

  useEffect(()=> {
    if (campaignId || emailId) {
      logPageLanding('register', emailId, campaignId)
          .then(() => {
            const currentUrl = new URL(window.location.href);
            const params = currentUrl.searchParams;
            params.delete('campaignId');
            params.delete('emailId');
            const newUrl = currentUrl.pathname + currentUrl.search + currentUrl.hash; // Reconstruct the URL
            window.history.pushState(null, '', newUrl);
          })
          .catch(() => {});
    }
  },[])

  function handleSubmit () {
    setFormErrors({})
    return validate(request).then(errs => {
      if (Object.keys(errs).length > 0) {
        setFormErrors(errs)
      } else {
        setLoading(true);
      // add timezone to request body and clean text
        setRequest(prevState => ({ ...prevState, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }))
        if (idx === 0) {
          ['firstName', 'lastName'].forEach(value => {
            const capital = capitalizeFLetter(request[value])
            setRequest(prevState => ({ ...prevState, [value]: capital }))
          })
          const clean = cleanPhone(request.phone)

          Root.userApi.phoneAvailable(clean).then((resp) => {
            if (resp.data) {
              setRequest(prevState => ({ ...prevState, phone: clean }))
              setShowMessagingSpeedBump(true);
            } else {
              setFormErrors({ 'phone': 'Phone number is already taken' })
            }
            setLoading(false);
          }).catch(() => setLoading(false));
        } else if (idx === 1) {
          setLoading(true);
          // get access code and validate username and email
          Root.userApi.getAccessCode(request.email, request.userName).then(res => {
            if (res.status === 200) {
              setIdx(prevState => (prevState + 1))
              setAccessCode(res.data)
            } else {
              const key = res.data.includes('Username') ? 'userName' : 'email'
              setFormErrors({ [key]: res.data })
            }
            setLoading(false)
          }).catch(error => {
            setLoading(false)
            const key = error.response.data.includes('Username') ? 'userName' : 'email'
            setFormErrors({ [key]: error.response.data })
          })
        } else if (idx === 2) {
          if (userCode !== accessCode.toString()) {
            setFormErrors({ accessCode: 'Invalid Access Code ' })
            return
          }
          setRequest({})
          setLoading(true)
          Root.userApi.registerBasicUser(request).then(res => {
            setSessionCookie(res.data);
            setLoading(false);
            setShowSuccessPage(true);
          }).catch(error => {
            setFormErrors({ accessCode: error.response.message })
            setLoading(false)
          }
          )
        }
      }
    })
  }

  function validate (request) {
    var errors = {}

    if (!validateName(request.firstName)) {
      push(errors, 'firstName', 'Valid first name is required ')
    }
    if (!validateName(request.lastName)) {
      push(errors, 'lastName', 'Valid last name is required ')
    }
    if (!validatePhone(request.phone)) {
      push(errors, 'phone', 'Valid US phone number is required ')
    }
    if (!request.studentType) {
      push(errors, 'studentType', 'Student type is required')
    }
    if (request.password && request.password !== confirmPassword) {
      push(errors, 'confirmPassword', 'Passwords do not match ')
    }
    if (!validatePassword(request.password)) {
      push(errors, 'password', 'Password must be at least 8 characters ')
    }

    if (idx === 1) {
      return handleValidateReferral().then(res => {
        if (!request.tos) {
          push(errors, 'tos', 'You must read and acknowledge the Terms and Conditions')
        }

        if (!validateEmail(request.email)) {
          push(errors, 'email', 'Valid email is required ')
        }
        if (request.email && request.studentType === 'CURRENT' && !request.email.endsWith('edu')) {
          if (request.college &&  !['217688','188526'].includes(request.college) ) {
            push(errors, 'email', 'Valid .edu email is required ')
          }
        }
        if (!validateUserName(request.userName)) {
          push(errors, 'userName', 'Username must at least be 5 characters. Numbers, letters, underscores and dashes are allowed ')
        }
        if (!validateDate(request.dateOfBirth)) {
          push(errors, 'dateOfBirth', 'Valid birthdate is required (MM/DD/YYYY) ')
        }
        if (request.dateOfBirth && !validateAge(request.dateOfBirth)) {
          push(errors, 'dateOfBirth', 'You must be 14 years or older to register ')
        }
        if (!request.graduationYear && request.studentType === 'CURRENT') {
          push(errors, 'graduationYear', 'Graduation Year is required ')
        }
        if (!request.college && request.studentType === 'CURRENT') {
          push(errors, 'college', 'University is required ')
        }
        var allErrs = Object.assign({}, res, errors)
        return allErrs
      })
    }
    return new Promise((resolve, reject) => {
      resolve(errors)
    })
  }

  function handleValidateReferral () {
    var errors = {}
    if (request && request.referrer) {
      return validateReferrer(request.referrer).then(
        (res) => {
          if (!res) push(errors, 'referrer', 'Not a valid referral')
          return errors
        })
    }
    return new Promise((resolve, reject) => {
      resolve(errors)
    })
  }

  function handleChange (event) {
    var { name, value } = event.target
    if (['email'].includes(name)) {
      value = value.trim()
    }
    if (name === 'confirmPassword') {
      setConfirmPassword(value)
    } else if (name === 'tos') {
      if (request.tos === 'true') {
        setRequest((prevState) => ({ ...prevState, ['tos']: null }));
      } else {
        setRequest((prevState) => ({ ...prevState, ['tos']: 'true' }));
      }
    } else {
      setRequest((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  function handleChangeUni (event) {
    if (event.length > 0) {
      setRequest(prevState => ({ ...prevState, college: event[0].id }))
    }
  }

  function handleChangeDay (selectedDay, modifiers, dayPickerInput) {
    setRequest(prevState => ({ ...prevState, dateOfBirth: dayPickerInput.getInput().value }))
  }

  function handleChangePhone (event) {
    if ((event && event.length === 1) || (event && !event.startsWith("1"))) {
      setRequest(prevState => ({ ...prevState, phone: `1${event}` }))
    } else {
      setRequest(prevState => ({ ...prevState, phone: event }))
    }
  }

  function pulseCheck() {
    Root.miscApi.serverPulseCheck()
    .then(() => {})
    .catch(()=> { toast.error("It looks like the server is restarting, please refresh in a few seconds!"); })
  }

  useEffect(()=>{
    pulseCheck()
  },[])

  return <div >
      {idx === 2 && !showSuccessPage && <p> A 6 digit access code has been sent to your email. Please enter the
       code to verify your account </p>}
      {idx < 2 && !showSuccessPage && <p className='text-muted fs-14'>Enter your info, then take a quick assessment to create your profile</p>}
    { !showSuccessPage && idx === 0 && showMessagingSpeedBump &&
        <Modal onHide={() => { setShowMessagingSpeedBump(false); }} show={showMessagingSpeedBump} className={styles.bookingModal}>
          <Modal.Header closeButton>
            <Modal.Title><strong>Receiving messages from us</strong></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{fontSize: '16px', textAlign: 'center', marginTop: '10px'}}>By continuing, you acknowledge you
              have read our <a href={'/privacy'} target={'_blank'} rel={"noreferrer"}>privacy policy</a> and agree to receive SMS & MMS notifications from NXSTEP.
              Message frequency varies. Msg. & Data Rates apply. You can opt-out of messages at anytime in the settings
              or by replying with 'STOP'.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button className={'btnLink fs-16 fw-bold'} onClick={() => {
              setIdx(1);
              setShowMessagingSpeedBump(false);
            }}>Continue</Button>
          </Modal.Footer>
        </Modal>
    }
    {!showSuccessPage && idx === 0 &&
        <Form>
          <div className="d-flex justify-content-between mb-3">
            <TextInput errors={formErrors} name={'firstName'} value={request.firstName} onChange={handleChange} placeholder={'First Name'}/>
                <TextInput errors={formErrors} name={'lastName'} value={request.lastName } onChange={handleChange} placeholder={'Last Name'}/>
              </div>
              <PhoneInput containerClassName='mb-3' disableCountryCode={false} name={'phone'} errors={formErrors} value={request.phone} onChange={handleChangePhone}
               placeholder={'Phone Number'}/>
              <TextInput containerClassName='mb-3' type={'password'} placeholder={'Password'} errors={formErrors} name={'password'} value={request.password} onChange={handleChange}/>
              <TextInput containerClassName='mb-3' type={'password'} placeholder={'Confirm Password'} errors={formErrors} name={'confirmPassword'} value={confirmPassword} onChange={handleChange}/>
              <Form.Group className="mb-1">
                <p>I am a ... </p>
                <Form.Check inline name="studentType" onChange={handleChange} value={'CURRENT'} checked={request.studentType === 'CURRENT'} label="Current College Student" type='checkbox'/>
                <Form.Check inline name="studentType" onChange={handleChange} value={'PROSPECTIVE'} checked={request.studentType === 'PROSPECTIVE'} label="College Applicant" type='checkbox' />
                <FormErrors errors={formErrors} name={'studentType'}/>
              </Form.Group>
              <div className={"d-flex justify-content-center mt-3"}>
                <Button onClick={handleSubmit} disabled={loading} className={`btnFill wide md`} style={{background: loading ? "grey" : ""}}>{loading ? <Spinner style={{color:"white"}} animation="border" role="status"/> : `Next`}</Button>
              </div>
            </Form>
      }
      { !showSuccessPage && idx === 1 && <>
        <Form style={{ marginTop: '3em' }}>
          <TextInput containerClassName='mb-3' errors={formErrors} name={'email'} value={request.email} onChange={handleChange} type={'email'} placeholder={`${request.studentType === 'CURRENT' ? 'School' : ''} Email`}/>
          <TextInput containerClassName='mb-3' errors={formErrors} name={'userName'} value={request.userName} onChange={handleChange} placeholder={'Username'}/>
          <DayInput containerClassName='mb-3' errors={formErrors} name={'dateOfBirth'} value={request.dateOfBirth} onChange={handleChangeDay} placeholder={'Birthdate (MM/DD/YYYY)'}/>

          {request.studentType === 'CURRENT' &&
              <div>
                <SelectInput containerClassName='mb-3' id={'Graduation Year'} placeholder={'Graduation Year'} options={gradYears} errors={formErrors} name={'graduationYear'} value={request.graduationYear} onChange={handleChange}/>
                <TypeAhead placeholder={'University'} options={collegeIds} containerClassName='mb-3'
                   errors={formErrors} name={'college'} value={request.college} onChange={handleChangeUni}/>
                {/*<TextInput errors={formErrors} name={'referrer'} value={request.referrer} onChange={handleChange} placeholder={'Username or Email'} label="Referral (Leave blank if not applicable)"/>*/}
              </div>
          }
          <div style={{paddingTop: '15px'}}>
          <Link className='btnLink mb-3 fs-12' target="_blank" to="/tos">Click to read the Terms of Service</Link>
          </div>
          <Form.Group style={{paddingBottom: '5px'}} className="mb-1">
            <Form.Check inline name="tos" onChange={handleChange} value={'true'} checked={request.tos === 'true'} label="I have read, and I agree to the Terms and Conditions" type='checkbox'/>
            <FormErrors errors={formErrors} name={'tos'}/>
          </Form.Group>
        </Form>
        <div className={"d-flex justify-content-between mt-3"}>
          <Button onClick={() => setIdx(0)} className={`btnFill md me-2`}>Back</Button>
          <Button onClick={handleSubmit} disabled={loading} className={`btnFill md ms-2`} style={{background: loading ? "grey" : ""}}>{loading ? <Spinner style={{color:"white"}} animation="border" role="status"/> : `Next`}</Button>
        </div>
      </>
      }
      { idx === 2 && <div className="mt-3">
        { !showSuccessPage && <>
          <Form>
            <TextInput errors={formErrors} name={'accessCode'} value={userCode} onChange={(event) => setUserCode(event.target.value)} placeholder={'6 Digit Access Code'}/>
            <div className={"d-flex justify-content-center mt-3"}>
              <Button onClick={handleSubmit} disabled={loading} className={`btnFill wide md`} style={{background: loading ? "grey" : ""}}>{loading ? <Spinner style={{color:"white"}} animation="border" role="status"/> : `Submit`}</Button>
            </div>
          </Form>
        </>}
      </div>
      }
      { showSuccessPage &&
        <>
          { showSuccessPage && <p style={{ textAlign: 'center' }}>Your account has been created! </p>}
          <div className={"d-flex justify-content-center"}>
            <Button className={`btnFill wide md`} href="/login">Log In</Button>
          </div>
        </>
      }
      {!showSuccessPage &&  <div className={"d-flex justify-content-center mt-3"}>
        <Link className='btnLink mb-3 fs-14' to="/login">Already have an account? Log In</Link>
      </div>}

      {/*{!showSuccessPage &&*/}
      {/*  <div className='d-flex justify-content-center'>*/}
      {/*    <Button className={`btnFill md mx-2 ${'blue'}`} href="/register/company" >Register a Company</Button>*/}
      {/*  </div>*/}
      {/*  }*/}
        <ToastContainer theme="dark" autoClose={false} position="bottom-center" />
  </div>
}
