import React, {useContext, useEffect, useState} from 'react'
import styles from './styles/Hermes.module.scss'
import {useLocation} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import {questions} from 'Utils/questions'
import logo from 'Static/Logo/nxstep-white.svg';
import hermesLogo from 'Static/hermes_nxstep_logo.png';
import {Root} from 'Root'
import {SessionContext} from 'Utils/sessions';

const Hermes = (props) => {
  const [personality, setPersonality] = useState({})
  const [basic, setBasic] = useState({})
  const [ipAddress, setIpAddress] = useState({})
  const [error, setError] = useState(null)
  const {session, refreshSession} =  useContext(SessionContext);
  const useQuery = () => { return new URLSearchParams(useLocation().search) }
  const reference = useQuery().get('reference')

  function redirect() {
    window.open('http://cassy.dev/hermes/chromeinstall', '_blank');
  }

  useEffect(() => {
    fetch('https://ifconfig.me/all.json')
      .then(res => res.json())
      .then(data => data.ip_addr)
      .then(ip => {
        setIpAddress(ip)
      })
      .catch(err => {
        console.log(err);
        setIpAddress("127.0.0.1");
      });
  }, []);

  useEffect(() => {
    Root.miscApi.logPageLanding("Hermes", reference)
    .then(() => {})
    .catch(() => {})
  }, [reference]);

  function downloadHermes() {
    setError(null)
    Root.miscApi.downloadHermes(reference, ipAddress).then(res => {
      if (res.status === 200) {
        redirect()
      }
    }).catch(error => {
      redirect()
    })
  }

  function markTaskDone(){
    Root.userApi.getAllNotifications(session.id)
    .then((rsp) => {
      let alerts = rsp.data
      alerts.unread.forEach(alert => {
        if(alert.subject.includes('NXSTEP Profile')){
          Root.userApi.markNotificationRead(session.id, alert.id)
        }
      })
    }).catch((err)=> {
      console.log(err)
    })
  }

  function handleSubmit () {
    const request = {
      userId: session.id,
      studentType: session.studentType,
      basicInformation: { ...basic, college: session.college || null },
      personality: personality
    }

    Root.userApi.createUserProfile(request).then(res => {
        markTaskDone()
    }).catch(() =>
      setError('Something went wrong, please try again or reach out to administrators if problem persists')
    )
  }

  function handlePersonalityChange (event) {
    const { name, value } = event.target
    setPersonality(prevState => ({ ...prevState, [name]: value }))
  }

  function handleBasicChange (event) {
    const { name, value } = event.target
    setBasic(prevState => ({ ...prevState, [name]: value }))
  }

  function validate (num) {
    if (typeof questions[num] === 'undefined') {
      return false
    }
    var key = questions[num].id
    if (!(key in basic) || key in personality) {
      if (['city', 'state'].includes(key)) {
        basic[key] = 'N/A'
        return true
      }
      return false
    }
    return true
  }

  function isFinalQuestion (num) {
    return num === questions.length - 1
  }

  return   <div className={`${styles.onboarding}`}>
    <Button className={`${styles.exit} btnLink white`} href='/dashboard/student'>Return</Button>
    <div className={styles.content}>
      <div className={styles.question}>
      <div>
      <img className={styles.hermesLogo} src={hermesLogo}/>
      <h5 className='mb-2 text-center 2E2B2B' style={{lineHeight:"30px"}}>Supercharge Your Reading: Ask insightful questions about any website or document and get clear, trustworthy answers from Hermes.</h5>
        <p>Hermes is the browser extension saving professionals valuable time on research and reading.</p>
        <p><strong>Upgrade your research experience:</strong>
          <br></br>
          - Get more than just a summary. Hermes tailors every website to meet your style of learning.
          <br></br>
          - Ask questions about a website's content for instant answers with citations.
          <br></br>
          - Compare multiple websites' topics and arguments seamlessly.
          <br></br>
        </p>
        <p>Simply install the extension and start using Hermes on any website or document</p>
      </div>
     <div >
         <Button className={'btnFill md mt-3 2A52BE'} onClick={downloadHermes}>Download Hermes</Button>
     </div>
     {error && <p className='form-error text-center fs-13'>{error}</p>}
        </div>
        </div>
        <img className={styles.logo} src={logo}/>
     </div>
}

export default (Hermes)
