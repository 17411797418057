import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import textLogo from 'Static/Logo/whitelogo.svg';
import darkTextLogo from 'Static/Logo/darklogo.svg';
import s from './NavBar.module.scss'

export const NavBar = ({style, className, dark}) => {
  return <Navbar expand="lg" style={{...style, padding:'5px 10px'}} className={`${s.nav} ${className}`}>
        <Navbar.Brand href="/">
            <img
                alt=""
                src={dark ? darkTextLogo : textLogo}
                height="45"
                className="d-inline-block align-center"
            />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav
            activeKey="/home"
            onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            className="me-auto">
            <Nav.Item>
                <Button href='/' className='btnLink dblue '>Students</Button>
            </Nav.Item>
            <Nav.Item>
                <Button href='/about' className='btnLink dblue '>About</Button>
            </Nav.Item>
            <Nav.Item>
                <Button href='/blog' className='btnLink dblue '>Blog</Button>
            </Nav.Item>
            <Nav.Item>
                <Button href='/partners' className='btnLink dblue '>Partners</Button>
            </Nav.Item>
            <Nav.Item>
                <Button href='/contact' className='btnLink dblue '>Contact Us</Button>
            </Nav.Item>
            <NavDropdown.Divider className={`${s.mobile} ${s.divider}`} />
            <Nav.Item className={s.mobile}>
                <Button className={`btnLink dblue`} href='/login'>Login</Button>
            </Nav.Item>
            <Nav.Item className={s.mobile}>
                <Button className={'btnLink dblue '} href='/register'> Sign Up</Button>
            </Nav.Item>
        </Nav>

        <Nav className={s.web}>
            <Button className={`btnOutline md me-2 dark ${s.login} ${s.web}`} href='/login'>Login</Button>
            <Button className={`btnFill md dark  ${s.web}`} href='/register'> Sign Up</Button>
        </Nav>
    </Navbar.Collapse>
</Navbar>
}
