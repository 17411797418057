import React, {useState} from 'react';
import logo from 'Static/Logo/logo-white.svg';
import Button from 'react-bootstrap/Button';
import s from './styles/Footer.module.scss';
import {AiFillFacebook, AiFillInstagram, AiFillLinkedin} from 'react-icons/ai'
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import {Root} from "../../Api/Root";

const Footer = () => {
  const [request, setRequest] = useState({});
  const [formResponse, setFormResponse] = useState(null)
  const [loading, setLoading] = useState(false)

  function subscribe() {
    if (!request.email || request.email.length === 0) {
      return
    }

    setLoading(true);
    Root.miscApi.subscribeEmail(request.email).then((res) => {
      setLoading(false);
      setFormResponse('Subscribed!');
      setRequest({});
    }).catch((res) => setLoading(false));
  }

  return <>
    <div className={s.footer}>

      <div className={`${s.grid}`}>
        <div>
            <span>
              <img
                  alt=""
                  src={logo}
                  height="25"
                  className="d-inline-block align-center"
              />
            </span>
          <p className="mt-4">Redefining college exploration through 1-on-1 live info sessions for college
            applicants.</p>
        </div>
        {/* <div>
            <p className={s.header}>For Students and Parents</p>
            <Button variant="link">Colleges</Button>
            <Button variant="link">Colleges By State</Button>
            <Button variant="link">College Discovery Center</Button>
            <Button variant="link">Test Prep</Button>
          </div> */}
        <div>
          <Button style={{color: 'white'}} variant="link" className='d-block text-start fs-13' href="/about">About
            Us</Button>
          <Button style={{color: 'white'}} variant="link" className='d-block text-start fs-13' href='/contact'>Contact
            Us</Button>
          <Button style={{color: 'white'}} variant="link" className='d-block text-start fs-13'
                  href="/blog">Blog</Button>
          <Button style={{color: 'white'}} variant="link" className='d-block text-start fs-13'
                  href="/jobs?pg=1">Jobs</Button>
          <Button style={{color: 'white'}} variant="link" className='d-block text-start fs-13' href="/tos">Terms and
            Conditions</Button>
          <Button style={{color: 'white'}} variant="link" className='d-block text-start fs-13' href="/privacy">Privacy
            Policy</Button>
        </div>
        <div>
          <InputGroup>
            <FormControl autoComplete="off"
                         style={{height: "auto"}}
                         maxLength={"255"}
                         placeholder={"Email"}
                         value={request.email || ''}
                         name={"email"}
                         onChange={(e) => {
                           setRequest({email: e.target.value});
                         }}/>
            <Button style={{height: "auto", borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}}
                    className='btnFill green'
                    onClick={() => {
                      subscribe();
                    }}>
              {loading ? <Spinner style={{color:"white", height: '16px', width: '16px'}} animation="border" role="status"/> : `Subscribe`}
            </Button>
          </InputGroup>
          <div style={{color: "white", fontSize: "14px"}}>{formResponse || null}</div>
          <div className="d-flex mt-2">
            <Button style={{color: 'white'}} variant="link" href='https://www.linkedin.com/company/nxstepco/'
                    target={'_blank'}><AiFillLinkedin size='23'/></Button>
            <Button style={{color: 'white'}} variant="link" href="https://www.instagram.com/nxstepco" target={'_blank'}><AiFillInstagram
                size='23'/></Button>
            <Button style={{color: 'white'}} variant="link" href="https://www.facebook.com/nxstepco"
                    target={'_blank'}><AiFillFacebook size='23'/></Button>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default (Footer);
