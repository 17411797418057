import React from 'react';
import {NavBar} from 'Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';

const Privacy = () => (
  <>
    <div className={s.blog}>
      <div className={s.sectionOne}>
      <NavBar />
        <div className={`${s.container} mt-5`}>
          <h1><strong>Privacy with NXSTEP</strong></h1>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} `}>
        <p><i>Last updated: Feb 13, 2025</i></p>
        <h2 id={'general'}>General</h2>
        <p>We at Cassy Technology Co. (the “<strong>Company</strong>” or “<strong>We</strong>“) respect your
          privacy and are committed to protecting it through our compliance with this policy.</p>
        <p>This policy describes the types of information we may collect from you or that you may provide when you
          visit the website/platform www.nxstep.co (the “<strong>Website</strong>“) and our practices for collecting,
          using,
          maintaining, protecting, and disclosing that information.</p>

        <p>This policy applies to information we collect:
          <ul>
            <li>On this Website.</li>
            <li>In email, text, and other electronic messages between you and this Website.</li>
            <li>Dedicated non-browser-based interaction between you and this Website.</li>
          </ul>
        </p>

        <p>It does not apply to information collected by:
          <ul>
            <li>Use offline or through any other means, including on any other website operated by the Company or any
              third party; or
            </li>
            <li>Any third party, including through any application or content (including advertising) that may link to
              or
              be accessible from or on the Website.
            </li>
          </ul>
        </p>

        <p>Please read this policy carefully to understand our policies and practices regarding your information and
          how we will treat it. If you do not agree with our policies and practices, your choice is not to use our
          Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from
          time to time (see <a href={'#changes'}>Changes to Our Privacy Policy</a>). Your
          continued use of this Website after we make changes is deemed to be acceptance of those changes, so please
          check the policy periodically for updates.</p>

        <h2>Children Under the Age of 13</h2>
        <p>Our Website is not intended for children under 13 years of age. No one under age 13 may provide any
          personal information to or on the Website. We do not knowingly collect personal information from children
          under 13. If you are under 13, do not use or provide any information on this Website or through any of its
          features, register on the Website, make any purchases through the Website, use any of the interactive or
          public comment features of this Website, or provide any information about yourself to us, including your
          name, photograph, email address, birthday, telephone number, LinkedIn Profile, or any username you may use.
          If we learn we have collected or received personal information from a child under 13 without verification
          of parental consent, we will delete that information. If you believe we might have any information from or
          about a child under 13, please contact us at <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a>.</p>

        <h2 id={'info-we-collect'}>Information We Collect About You and How We Collect It</h2>
        <p>We collect several types of information from and about users of our Website, including information:
          <ul>
            <li>By which you may be personally identified, such as name, photograph, email address, birthday, telephone
              number, LinkedIn Profile, or any other identifier by which you may be contacted online or offline
              (“personal information“);
            </li>
            <li>That is about your activity on our Website, such as comments and posts, pages visited, videos watched,
              users followed, type of device you are using, and if you were redirected through a third party website or
              application such as Instagram or Facebook;
            </li>
            <li>That is about you but individually does not identify you, such as your high school, preparatory program,
              university, graduation date, major(s), minor(s), club participation, financial aid, sport participation,
              military training, language(s), and preferred language; and/or
            </li>
            <li>About your internet connection, the equipment you use to access our Website, usage details, and times
              and dates of visits to the site.
            </li>
          </ul>
        </p>

        <p>We collect this information:
          <ul>
            <li>Directly from you when you provide it to us.</li>
            <li>Automatically as you navigate through the site. Information collected automatically may include usage
              details, IP addresses, and information collected through cookies, web beacons, and other tracking
              technologies.
            </li>
          </ul>
        </p>

        <p>Information You Provide to Us: The information we collect on or through our Website may include:
          <ul>
            <li>Personal Information. Personal information collected when you register for an account or enroll in a
              membership plan.
            </li>
            <li>Website Use. Information that you provide by filling in forms on our Website. This includes information
              provided at the time of registering to use our Website, subscribing to our service, posting material, or
              requesting further services. We may also ask you for information when you enter a contest or promotion
              sponsored by us, and when you report a problem with our Website.
            </li>
            <li>Correspondence. Records and copies of your correspondence (including email addresses), if you contact
              us.
            </li>
            <li>Surveys. Your responses to surveys that we might ask you to complete for research purposes.</li>
            <li>Transactions. Details of transactions you carry out through our Website and of the fulfillment of your
              orders. You may be required to provide financial information before placing an order through our Website.
            </li>
            <li>Searches. Your search queries on the Website.</li>
          </ul>
        </p>

        <p>
          You also may provide information to be published or displayed (hereinafter, “posted“) on public areas of the
          Website, or transmitted to other users of the Website or third parties (collectively, “User Contributions“).
          Your User Contributions are posted on and transmitted to others at your own risk. Although you can access
          and use the Website by logging onto your own personal account, please be aware that no security measures
          are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with
          whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your
          User Contributions will not be viewed by unauthorized persons.
        </p>

        <p>Information We Collect Through Automatic Data Collection Technologies</p>

        <p>As you navigate through and interact with our Website, we may use automatic data collection technologies
          to collect certain information about your equipment, browsing actions, and patterns, including:
          <ul>
            <li>Details of your visits to our Website, including posts, pages visited, videos watched, users followed,
              traffic data, location data, logs, and other communication data and the resources that you access and use
              on the Website.
            </li>
            <li>Information about your computer and internet connection, including your device type, country, if you
              were
              redirected through a third party site such as Instagram or Facebook, IP address, operating system, and
              browser type.
            </li>
          </ul>
        </p>

        <p>
          The information we collect automatically may include personal information, or we may maintain it or associate
          it with personal information we collect in other ways. It helps us to improve our Website and to deliver a
          better and more personalized service, including by enabling us to:
          <ul>
            <li>Estimate our audience size and usage patterns.</li>
            <li>Store information about your preferences, allowing us to customize our Website according to your
              individual interests.
            </li>
            <li>Speed up your searches.</li>
            <li>Recognize you when you return to our Website.</li>
          </ul>
        </p>

        <p>
          The technologies we use for this automatic data collection may include:
          <ul>
            <li>Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You
              may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if
              you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted
              your browser setting so that it will refuse cookies, our system will issue cookies when you direct your
              browser to our Website.
            </li>
            <li>Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to
              collect and store information about your preferences and navigation to, from, and on our Website. Flash
              cookies are not managed by the same browser settings as are used for browser cookies. For information
              about managing your privacy and security settings for Flash cookies, see <a href={'#choices'}>Choices
                About How We Use and Disclose Your Information</a>.
            </li>
            <li>Web Beacons. Pages of the Website and our emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example,
              to count users who have visited those pages or opened an email and for other related website statistics
              (for example, recording the popularity of certain website content and verifying system and server
              integrity).
            </li>
            <li>Recognize you when you return to our Website.</li>
          </ul>
        </p>

        <h2 id={'how-we-use'}>How We Use Your Information</h2>
        <p>
          We use information that we collect about you or that you provide to us, including any personal information:
          <ul>
            <li>To present our Website and its contents to you.</li>
            <li>To provide you with information, products, or services that you request from us.</li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>To provide you with notices about your subscription, including expiration and renewal notices.</li>
            <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you
              and us, including for billing and collection.
            </li>
            <li>To notify you about changes to our Website or any products or services we offer or provide though it.
            </li>
            <li>To allow you to participate in interactive features on our Website.</li>
            <li>In any other way we may describe when you provide the information.</li>
            <li>For any other purpose with your consent.</li>
          </ul>
        </p>

        <p>
          We may also use some of your information to contact you about our own and third-parties’ goods and services
          that may be of interest to you.
        </p>

        <h2 id={'disclosure'}>Disclosure of Your Information</h2>

        <p>We may disclose aggregated information about our users, and information that does not identify any
          individual, without restriction.</p>
        <p>
          We may disclose personal information that we collect or you provide as described in this privacy policy:
          <ul>
            <li>To contractors, service providers, and other third parties we use to support our business and who are
              bound by contractual obligations to keep personal information confidential and use it only for the
              purposes for which we disclose it to them.
            </li>
            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of NXSTEP’s assets, whether as a going concern or as
              part of bankruptcy, liquidation, or similar proceeding, in which personal information held by NXSTEP about
              our Website users is among the assets transferred.
            </li>
            <li>To third parties to market their products or services to you if you have consented to/not opted out of
              these disclosures. We contractually require these third parties to keep personal information confidential
              and use it only for the purposes for which we disclose it to them. For more information, see Choices About
              How We Use and Disclose Your Information.
            </li>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>For any other purpose disclosed by us when you provide the information.</li>
            <li>With your consent.</li>
            <li>No mobile information will be shared with third parties/affiliates for marketing/promotional purposes.
            </li>
          </ul>
        </p>

        <p>Relating with third-parties:
          <ul>
            <li>No mobile information will be shared with third parties/affiliates for marketing/promotional
              purposes.
            </li>
            <li>Mobile information sharing to subcontractors in support services, such as customer service is permitted.</li>
            <li>We may also share you mobile information, such as your phone number, for purposes related to
              anti-fraud and anti-abuse.</li>
            <li>Your mobile information may also be shared with third-parties to carry out the functionality and
              service of the Website.</li>
            <li>All other use case categories exclude text messaging originator opt-in data and consent; this
              mobile information will not be shared with any third parties.
            </li>
          </ul>
        </p>

        <p>
          We may also disclose your personal information:
          <ul>
            <li>To comply with any court order, law, or legal process, including to respond to any government or
              regulatory request.
            </li>
            <li>To enforce or apply our <a href={'/tos'} target={'_blank'} rel={'noreferrer'}>terms of use</a> and other
              agreements, including for billing and collection purposes.
            </li>
            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
              NXSTEP, our customers, or others. This includes exchanging information with other companies and
              organizations for the purposes of fraud protection and credit risk reduction.
            </li>
          </ul>
        </p>

        <p>If you do not want us to use or disclose of your information in this way, please email us
          at <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a> or send us a message via the “Contact” form
          in <a href={'/settings'}>Settings</a> or <a href={'/contact'}>Contact</a>. For more information,
          see <a href={'#choices'}>Choices About How We Use and Disclose Your Information</a>.
        </p>

        <h2 id={'ugc'}>Information and Content You Provide</h2>
        <p>Account-holders may create a profile and upload content such as videos, text, and photographs. Your profile
          may display information about you and your activities. This information may be accessed by others. Your
          videos may be viewed and otherwise accessed by others, and associated metadata (e.g., titles, descriptions,
          tags, etc.) are viewable by others. Your interactions with other users (e.g., comments, “likes,” private
          messages) may be seen by others. With your permission, content you post may be shared to NXSTEP’s other
          social media accounts such as Instagram.</p>
        <p>People who can see your activity on our site can choose to share it with others on and off our site,
          including people and businesses outside the audience you shared with. For example, when you share a post or
          send a message to specific friends or accounts, they can download, screenshot, or reshare that content to
          others across or off our Website. Also, when you comment on someone else’s post or react to their content,
          your comment or reaction is visible to anyone who can see the other person’s content, and that person can
          change the audience later.</p>

        <h2 id={'choices'}>Choices About How We Use and Disclose Your Information</h2>
        <p>
          We strive to provide you with choices regarding the personal information you provide to us. We have created
          mechanisms to provide you with the following control over your information:
          <ul>
            <li>Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies,
              or
              to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit
              the
              Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that some
              parts
              of this site may then be inaccessible or not function properly.
            </li>
            <li>Promotional Offers from the Company. If you do not wish to have your email address/contact information
              used by the Company to promote our own or third parties’ products or services, you can opt-out by
              sending us a message via the “Contact” form in <a href={'/settings'}>Settings</a> or by sending us an
              email
              stating your request to <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a>. If we have sent you a
              promotional email, you may email us at <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a> asking
              to be omitted from future email distributions. This opt out does not apply to information provided to the
              Company as a result of a product purchase, warranty registration, product service experience or other
              similar transactions.
            </li>
          </ul>
        </p>

        <h2 id={'access-and-correction'}>Accessing, Correcting, and Updating Your Information</h2>
        <p>You can review and change your personal information by logging into the Website and visiting your account
          profile page.</p>
        <p>You may also send us a message via the contact form on your in <a href={'/settings'}>Settings</a> or send us
          an email at
          <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a> to request access to, correct, or delete any
          personal information that you have provided to us. We cannot delete your personal information except by also
          deleting your user account. We may not accommodate a request to change information if we believe the change
          would violate any law or legal requirement or cause the information to be incorrect.</p>
        <p>If you delete your User Contributions from the Website, copies of your User Contributions may remain
          viewable in cached and archived pages, or might have been copied or stored by other Website users.
          User Contributions shared directly with other users in private message rooms or channels on the Website
          may also remain accessible to the other party or recipient after deletion. Proper access and use of
          information provided on the Website, including User Contributions, is governed by
          our <a href={'/tos'} target={'_blank'} rel={'noreferrer'}>terms of use</a>.</p>

        <h2 id={'data-security'}>Data Security</h2>
        <p>We have implemented measures designed to secure your personal information from accidental loss and from
          unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our
          secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.</p>
        <p>The safety and security of your information also depends on you. Where you have chosen a password for
          access to certain parts of our Website, you are responsible for keeping this password confidential. We ask
          you not to share your password with anyone. We urge you to be careful about giving out information in public
          areas of the Website like message boards. The information you share in public areas may be viewed by any
          user of the Website.</p>
        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do
          our best to protect your personal information, we cannot guarantee the security of your personal information
          transmitted to our Website. Any transmission of personal information is at your own risk. We are not
          responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
        <p>We will retain your information as long as necessary, or permitted, based on the reason why we obtained it,
          and for a commercially reasonable time thereafter for backup, archival, fraud prevention or detection, or
          audit purposes, or as otherwise required by law.</p>

        <h2 id={'fonts'}>Google Web Fonts</h2>
        <p>For uniform representation of fonts, this page uses web fonts provided by Google. When you open a page,
          your browser loads the required web fonts into your browser cache to display texts and fonts correctly.</p>
        <p>For this purpose your browser has to establish a direct connection to Google servers. Google thus becomes
          aware that our web page was accessed via your IP address. The use of Google Web fonts is done in the
          interest of a uniform and attractive presentation of our Website. This constitutes a justified interest
          pursuant to Art. 6 (1) (f) DSGVO.</p>
        <p>If your browser does not support web fonts, a standard font is used by your computer.</p>
        <p>Further information about handling user data, can be found
          at <a href={'https://developers.google.com/fonts/faq'} target={'_blank'}
                rel={'noreferrer'}>https://developers.google.com/fonts/faq</a> and
          in Google’s privacy policy
          at <a href={'https://www.google.com/policies/privacy/'} target={'_blank'}
                rel={'noreferrer'}>https://www.google.com/policies/privacy/</a>.
        </p>

        <h2 id={'changes'}>Changes to Our Privacy Policy</h2>
        <p>It is our policy to post any changes we make to our privacy policy on this page with a notice that the
          privacy policy has been updated on the Website home page. If we make material changes to how we treat our
          users’ personal information, we will notify you by email to the email address specified in your account
          and through a notice on the Website home page. The date the privacy policy was last revised is identified at
          the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email
          address for you, and for periodically visiting our Website and this privacy policy to check for any
          changes.</p>
        <h2>Contact Information</h2>
        <p>To ask questions or comment about this privacy policy and our privacy practices,
          contact us at: <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a></p>
      </div>
    </div>
    <Footer/>
  </>
);

export default (Privacy);
