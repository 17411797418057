import React from 'react';
import {NavBar} from 'Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';
import Button from "react-bootstrap/esm/Button";

const Phoenix = () => {
      function handleClick(name) {
            if (name === 'quest3s') {
                  window.open('https://www.meta.com/quest/quest-3s/', '_blank');
            } else if (name === 'theragun') {
                  window.open('https://www.therabody.com/us/en-us/theragun-prime-master.html?dwvar_theragun-prime-master_color=black&cgid=products#start=1', '_blank');
            }
      }

      return <>
            <div className={`${s.blog}`}>
                  <div className={`${s.sectionOne}`}>
                        <NavBar/>
                        <div className={`${s.container} mt-5`}>
                              <h1><strong>Phoenix Contest</strong></h1>
                              <h3 style={{marginBottom: '0'}}><strong>Relaunching with our existing community</strong>
                              </h3>
                        </div>
                  </div>
                  <div className={`${s.sectionTwo} ${s.container}`}>
                        <p><i>Published: Feb 19, 2025</i></p>
                        <h2 style={{marginTop: "40px"}}>What</h2>
                        <p>NXSTEP has been dormant for some time, due to various reasons, but it has undergone some
                              major revisions and upgrades, and it is now poised for a relaunch. </p>
                        <p>To relaunch NXSTEP and get the word out, we have created a referral contest, <i>Phoenix
                              Contest</i>, to
                              reengage the previous members of our community to help spread the word more quickly. Some
                              of the previous members are eligible to become NXSTEP Partners and, in a limited
                              capacity, act on behalf of NXSTEP to expand and grow the platform.</p>
                        <p>During the time of the contest, which is outlined below, NXSTEP Partners
                              can compete with other NXSTEP Partners for a chance to win 1 of 2 prizes. The
                              Partner with the highest total count of new users who register to the platform and
                              credit the Partner for their referral (a completed referral) will receive first place.
                              If there is a tie, the Partner who sent out more invitations to join the platform
                              should win the tie.</p>
                        <p>Partners must have at least 10 completed referrals to be eligible for winning. If no
                              Partner is able achieve or gain 10 completed referrals, there shall be no winner of the
                              contest.</p>
                        <h4 style={{marginTop: "24px"}}>Prize</h4>
                        <p>First place winner will have the choice of receiving either a
                              <Button className={'btnLink p-1 fs-14'}
                                      onClick={() => handleClick('quest3s')}>Meta Quest 3S</Button> or
                              a <Button className={'btnLink p-1 fs-14'} onClick={() => handleClick('theragun')}>Theragun
                                    Prime (5th Gen)</Button>.
                        </p>


                        <h2 style={{marginTop: "40px"}}>Who</h2>
                        <p>Participants of the <i>Phoenix Contest</i> have been randomly selected from the pool
                              of college students who had joined the platform prior to Feb 1, 2025. Many, if not all
                              of these users have graduated or are no longer enrolled in college and are therefore no
                              longer eligible to be NXSTEP Mentors. These prior users have been automatically enrolled
                              into the NXSTEP Partner program. When logging into their accounts, these Partners
                              are
                              prompted to acknowledge the Terms & Conditions before activating their new accounts.</p>
                        <p>If you believe you are eligible to be a NXSTEP Partner but have not received a
                              notification with information to gain access your account, please email us
                              at <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a>.</p>

                        <p>Similarly, if you do not wish to be contacted for any more promotional offers, please reach
                              out to us and let us know.</p>

                        <h2 style={{marginTop: "40px"}}>When</h2>
                        <p>The <i>Phoenix Contest</i> will start no later than February 20, 2025 at 12:00 AM EST and end
                              on March
                              5,
                              2025 at 11:59 PM EST.</p>
                        <p>Winners of the Contest will be announced on March 5, 2025, and they will be able to select
                              and
                              collect their prize within the 4 weeks following.</p>
                        <p>Once the <i>Phoenix Contest</i> concludes, Partners will still be able to refer new
                              users to the platform and earn cash for each referral. Details regarding exact
                              compensation
                              amounts can be found on the Partner's <a href={'/partners'}>dashboard</a>.</p>

                        <h2 style={{marginTop: "40px"}}>Why</h2>
                        <p>There is no better way to rebuild the NXSTEP Platform than with the community who was there
                              with us from the beginning. Many of you loved the platform then but are unable to
                              participate in the same way today, as you did before as mentors, so we want to give you
                              the
                              opportunity to participate in another, yet just as meaningful way.</p>

                        <h2 style={{marginTop: "40px"}}>More</h2>
                        <p style={{marginBottom: "80px"}}>
                              Terms are subject to change. If any change occurs, you will be notified via text message
                              and
                              email. If you have any questions, please do no hesitate to reach out by submitting a
                              request
                              through our <a href={'/contact'}>Contact</a> form or by emailing us directly
                              at <a href={'mailto:support@nxstep.co'}>support@nxstep.co</a>.</p>
                  </div>
            </div>
            <Footer/>
      </>
};

export default (Phoenix);
